<template>
  <v-dialog v-model="isDocumentGeneratorOpen" fullscreen hide-overlay persistent>
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-toolbar color="rgba(78, 78, 78, 0.04)" flat>
        <v-btn icon @click="resetData">
          <v-icon class="black--text">{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title
          v-t="{
            path: 'createDocumentFor',
            args: {
              target,
            },
          }"
          class="black--text"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="!documentGenerated"
          color="primary"
          :loading="generateDocumentLoading"
          :disabled="!valid || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
          @click="handleFormSubmit"
        >
          {{ $t('generatePreview') }}
        </v-btn>
        <v-btn
          v-if="documentGenerated"
          outlined
          color="primary"
          class="me-3"
          :disabled="savingDocumentLoading"
          @click="backToEditor"
        >
          <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
          <span v-t="'edit'" />
        </v-btn>
        <v-btn
          v-if="documentGenerated"
          color="primary"
          :loading="savingDocumentLoading"
          :disabled="!valid || !base64File || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
          @click="uploadFile"
        >
          {{ $t('save') }}
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <upgrade-alert-wrapper class="mb-3" :form="false">
          <template #blocked-features>
            <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
              <v-row align-content="center" justify="center">
                <v-col cols="12" lg="7">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="template"
                      outlined
                      dense
                      :items="documentTemplatesChoices"
                      :label="$t('selectDocumentTemplate')"
                      :placeholder="$t('selectDocumentTemplate')"
                      hide-details="auto"
                      :rules="[validators.required]"
                      return-object
                      :disabled="documentGenerated || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                      @change="setDocumentTemplateData"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-row v-if="template">
                      <v-col cols="12" lg="4">
                        <v-text-field
                          v-model="file.name"
                          :label="$t('name')"
                          :placeholder="$t('name')"
                          outlined
                          dense
                          maxlength="150"
                          hide-details="auto"
                          :rules="[validators.required]"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        />
                      </v-col>
                      <v-col v-if="showExtraFileFields" cols="6" lg="4">
                        <v-combobox
                          v-model="file.types"
                          :items="fileTypes"
                          :label="$t('type')"
                          :placeholder="$t('type')"
                          item-text="type"
                          item-value="id"
                          multiple
                          dense
                          outlined
                          hide-details="auto"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        />
                      </v-col>
                      <v-col v-if="showExtraFileFields" cols="6" lg="4">
                        <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="file.validUntil"
                              :label="$t('validUntil', { date: '' })"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="attrs"
                              hide-details="auto"
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="file.validUntil"
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col v-if="showAssignmentField && assignments" cols="12">
                        <v-autocomplete
                          v-model="file.context.assignment"
                          :items="assignments"
                          :label="$t('assignment')"
                          :placeholder="$t('assignment')"
                          outlined
                          dense
                          hide-details="auto"
                          text
                          :disabled="documentGenerated || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          item-value="id"
                          :item-text="(item) => formatAssignment(item)"
                        >
                          <template v-if="file.context.assignment" #append-outer>
                            <v-fade-transition leave-absolute>
                              <v-icon color="primary" @click="goToPositionDetailsPage">{{ icons.mdiOpenInNew }}</v-icon>
                            </v-fade-transition>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="showAddressesFields && customAddresses" cols="12">
                        <v-autocomplete
                          v-model="file.context.customAddress"
                          :items="customAddresses"
                          :label="$t('customAddress')"
                          :placeholder="$t('customAddress')"
                          outlined
                          dense
                          hide-details="auto"
                          text
                          :disabled="documentGenerated || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          item-value="id"
                          item-text="name"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="showAddressesFields && accommodations" cols="12">
                        <v-autocomplete
                          v-model="file.context.companyAddress"
                          :items="accommodations"
                          :label="$t('companyAddress')"
                          :placeholder="$t('companyAddress')"
                          outlined
                          dense
                          hide-details="auto"
                          text
                          :disabled="documentGenerated || !$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          item-value="id"
                          item-text="roomName"
                        >
                          <template v-if="file.context.companyAddress" #append-outer>
                            <v-fade-transition leave-absolute>
                              <v-icon color="primary" @click="goToRoomDetailsPage">{{ icons.mdiOpenInNew }}</v-icon>
                            </v-fade-transition>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="template && !documentGenerated" cols="12">
                    <summer-note
                      :key="rerenderSummernoteTrigger"
                      :email-variables="documentVariablesSummernote"
                      :custom-fields-variables="customFieldsVariables"
                      :height="700"
                      hide-link-and-image-button
                      @ready="readySummerNote"
                    />
                  </v-col>
                  <v-col v-else-if="documentGenerated" cols="12">
                    <object :data="fileUrl" type="application/pdf" class="PDF-Viewer__object w-full" />
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </upgrade-alert-wrapper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, getCurrentInstance, onMounted, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import {
  mdiClose,
  mdiDownload,
  mdiPrinter,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiRotateLeft,
  mdiRotateRight,
  mdiPencilOutline,
  mdiOpenInNew,
} from '@mdi/js'

import { formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import {
  DOCUMENT_EMPLOYEES_VARIABLES,
  DOCUMENT_HOUSES_SHINE_VARIABLES,
  DOCUMENT_PROJECTS_SHINE_VARIABLES,
  DOCUMENT_CLIENTS_SHINE_VARIABLES,
  DOCUMENT_CANDIDATES_VARIABLES,
  parseCustomFieldsToVariables,
  mapNestedModuleCustomFieldsToVariables,
} from '@/plugins/summer-note/variables'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import assigneeTableUtils from '@/utils/assigneeTableUtils'
import { PRIVATE, ACTIONS } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: {
    UpgradeAlertWrapper,
    SummerNote,
  },
  props: {
    isDocumentGeneratorOpen: {
      type: Boolean,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
    fileTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    obj: {
      type: Object,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    assignments: {
      type: Array,
      required: false,
      default: () => [],
    },
    customAddresses: {
      type: Array,
      required: false,
      default: () => [],
    },
    accommodations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const valid = ref(true)
    const generateDocumentLoading = ref(false)
    const savingDocumentLoading = ref(false)

    const bioEditForm = ref(null)

    const documentGenerated = ref(false)

    const file = ref({
      name: '',
      types: [],
      validUntil: null,
      contentHtml: '',
      context: {
        assignment: null,
        customAddress: null,
        companyAddress: null,
      },
    })

    const { getCandidateCustomFields } = useActions('company', ['getCandidateCustomFields'])
    const { getHouseCustomFields, getProjectCustomFields, getClientCustomFields } = useActions('staff', [
      'getHouseCustomFields',
      'getProjectCustomFields',
      'getClientCustomFields',
    ])
    const { uploadFileCandidateInOffer } = useActions('jobs', ['uploadFileCandidateInOffer'])

    const { MODULE_EMPLOYEES, MODULE_HOUSES, MODULE_PROJECTS, MODULE_CLIENTS, MODULE_CANDIDATES } =
      documentTemplateUtils()

    const fileUrl = ref(null)

    const customFieldsVariables = ref([])

    const showExtraFileFields = computed(() => {
      return props.module !== MODULE_CANDIDATES
    })

    const { formatDateEndColumn } = assigneeTableUtils()
    const showAssignmentField = computed(() => {
      return props.module === MODULE_EMPLOYEES
    })
    const showAddressesFields = computed(() => {
      return props.module === MODULE_EMPLOYEES
    })
    const formatAssignment = (item) => {
      return `${item.positionName} - ${item.projectName} (${formatDateToMonthShort(
        item.dateStart,
        false,
      )} - ${formatDateEndColumn(item)})`
    }
    const { router } = useRouter()
    const goToPositionDetailsPage = () => {
      if (!file.value.context.assignment) return
      const assignmentData = props.assignments.find((assignment) => assignment.id === file.value.context.assignment)
      const route = router.resolve({ name: 'apps-project-position-view', params: { id: assignmentData.position } })
      window.open(route.href, '_blank')
    }

    const goToRoomDetailsPage = () => {
      if (!file.value.context.companyAddress) return
      const accommodationData = props.accommodations.find(
        (accommodation) => accommodation.id === file.value.context.companyAddress,
      )
      const route = router.resolve({ name: 'apps-house-room-view', params: { id: accommodationData.roomId } })
      window.open(route.href, '_blank')
    }

    const { getDocumentTemplatesChoices, generateDocument } = useActions('templates', [
      'getDocumentTemplatesChoices',
      'generateDocument',
    ])
    const { uploadEmployeeFiles, uploadHouseFiles, uploadProjectFiles, uploadClientFiles } = useActions('staff', [
      'uploadEmployeeFiles',
      'uploadHouseFiles',
      'uploadProjectFiles',
      'uploadClientFiles',
    ])

    const documentTemplatesChoices = ref([])
    const template = ref(null)
    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)
    const documentVariablesSummernote = ref([])

    const setDocumentVariables = () => {
      if (props.module === MODULE_EMPLOYEES) {
        documentVariablesSummernote.value = DOCUMENT_EMPLOYEES_VARIABLES(vm)
      } else if (props.module === MODULE_HOUSES) {
        documentVariablesSummernote.value = DOCUMENT_HOUSES_SHINE_VARIABLES(vm)
      } else if (props.module === MODULE_PROJECTS) {
        documentVariablesSummernote.value = DOCUMENT_PROJECTS_SHINE_VARIABLES(vm)
      } else if (props.module === MODULE_CLIENTS) {
        if (vm.$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES))
          documentVariablesSummernote.value = DOCUMENT_CLIENTS_SHINE_VARIABLES(vm)
      } else if (props.module === MODULE_CANDIDATES) {
        documentVariablesSummernote.value = DOCUMENT_CANDIDATES_VARIABLES(vm)
      }
    }
    setDocumentVariables()

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', file.value.contentHtml)
      editor.value.$on('change', (content) => {
        file.value.contentHtml = content
      })
    }

    const setDocumentTemplateData = (templateData) => {
      file.value.contentHtml = templateData.contentHtml
      file.value.name = templateData.text
      editor.value?.summernote('destroy')
      rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
    }

    const documentTemplateDataLocal = ref({})

    const base64File = ref('')

    const resetData = () => {
      bioEditForm.value.reset()
      base64File.value = ''
      fileUrl.value = null
      documentGenerated.value = false
      editor.value?.summernote('destroy')
      emit('update:is-document-generator-open', false)
    }

    const backToEditor = () => {
      fileUrl.value = null
      base64File.value = ''
      documentGenerated.value = false
    }

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      generateDocumentLoading.value = true
      const { data, success } = await generateDocument({
        obj: props.obj,
        contentHtml: file.value.contentHtml,
        module: props.module,
        fileName: file.value.name,
        context: file.value.context,
      })
      generateDocumentLoading.value = false

      if (success) {
        base64File.value = data
        fileUrl.value = `data:application/pdf;base64,${data}`
        editor.value.summernote('destroy')
        documentGenerated.value = true
      }
    }

    const uploadFile = async () => {
      const payload = [
        {
          name: file.value.name,
          validUntil: file.value.validUntil,
          types: file.value.types.map((fileType) => (_.isPlainObject(fileType) ? fileType : { type: fileType })),
          file: base64File.value,
        },
      ]
      if (props.module === MODULE_EMPLOYEES) {
        const { success } = await uploadEmployeeFiles({
          employeeId: props.obj.id,
          payload,
        })
        if (success) {
          emit('refetch-data')
          resetData()
        } else {
          resetData()
        }
      } else if (props.module === MODULE_HOUSES) {
        const { success } = await uploadHouseFiles({
          houseId: props.obj.id,
          payload,
        })
        if (success) emit('refetch-data')
      } else if (props.module === MODULE_PROJECTS) {
        const { success } = await uploadProjectFiles({
          projectId: props.obj.id,
          payload,
        })
        if (success) emit('refetch-data')
      } else if (props.module === MODULE_CLIENTS) {
        const { success } = await uploadClientFiles({
          clientId: props.obj.id,
          payload,
        })
        if (success) emit('refetch-data')
      } else if (props.module === MODULE_CANDIDATES) {
        const candidateOfferDetailsPayload = _.cloneDeep(props.obj)
        candidateOfferDetailsPayload.files.push({
          name: file.value.name,
          file: base64File.value,
        })
        const { success } = await uploadFileCandidateInOffer({
          candidateDetailId: props.obj.id,
          payload,
        })
        if (success) emit('refetch-data')
      }
      resetData()
    }

    watch(
      () => props.isDocumentGeneratorOpen,
      async () => {
        if (vm.$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)) {
          documentTemplatesChoices.value = await getDocumentTemplatesChoices({ module: props.module })

          let customFields = []
          if (props.module === MODULE_EMPLOYEES) {
            customFields = await getCandidateCustomFields()
            const houseCustomFields = await getHouseCustomFields()
            const projectCustomFields = await getProjectCustomFields()
            const clientCustomFields = await getClientCustomFields()

            customFieldsVariables.value = [
              ...parseCustomFieldsToVariables(customFields, props.module),
              ...mapNestedModuleCustomFieldsToVariables(houseCustomFields, MODULE_EMPLOYEES, MODULE_HOUSES, vm),
              ...mapNestedModuleCustomFieldsToVariables(projectCustomFields, MODULE_EMPLOYEES, MODULE_PROJECTS, vm),
              ...mapNestedModuleCustomFieldsToVariables(clientCustomFields, MODULE_EMPLOYEES, MODULE_CLIENTS, vm),
            ]
          } else if (props.module === MODULE_HOUSES) {
            customFields = await getHouseCustomFields()
            customFieldsVariables.value = parseCustomFieldsToVariables(customFields, props.module)
          } else if (props.module === MODULE_PROJECTS) {
            customFields = await getProjectCustomFields()
            customFieldsVariables.value = parseCustomFieldsToVariables(customFields, props.module)
          } else if (props.module === MODULE_CLIENTS) {
            customFields = await getClientCustomFields()
            customFieldsVariables.value = parseCustomFieldsToVariables(customFields, props.module)
          } else if (props.module === MODULE_CANDIDATES) {
            customFields = await getCandidateCustomFields()
            customFieldsVariables.value = parseCustomFieldsToVariables(customFields, props.module)
          }
        }
      },
    )

    return {
      handleFormSubmit,
      backToEditor,
      resetData,
      documentGenerated,
      fileUrl,
      base64File,
      customFieldsVariables,

      documentTemplatesChoices,
      template,
      file,
      editor,
      documentVariablesSummernote,
      rerenderSummernoteTrigger,
      readySummerNote,
      setDocumentTemplateData,
      uploadFile,

      valid,
      generateDocumentLoading,
      savingDocumentLoading,
      bioEditForm,
      documentTemplateDataLocal,

      showExtraFileFields,

      showAssignmentField,
      showAddressesFields,
      formatAssignment,
      goToPositionDetailsPage,
      goToRoomDetailsPage,

      icons: {
        mdiClose,
        mdiDownload,
        mdiPrinter,
        mdiChevronRight,
        mdiChevronLeft,
        mdiPlus,
        mdiMinus,
        mdiRotateLeft,
        mdiRotateRight,
        mdiPencilOutline,
        mdiOpenInNew,
      },

      validators: {
        required,
      },

      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style lang="scss" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  padding: 24px 16px;
}

.right {
  float: right;
}

.test {
  height: 400px;
  overflow: auto;
}
.vue-pdf-embed--container {
  //border: 3px solid black;
  width: 100%;
  overflow-x: auto;
  background-color: #ccc;
}

.some-test {
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.PDF-Viewer__object {
  height: 70vh;
}
</style>
