import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'

export default function clientUtils() {
  const statusChoices = ref([])
  const allEmployeesChoices = ref([])

  const { getClientStatusChoices, getAllEmployeesChoices, getClientsFileTypes, getClientTags } = useActions(
    'staff',
    ['getClientStatusChoices', 'getAllEmployeesChoices', 'getClientsFileTypes', 'getClientTags'],
  )

  const arrangeAllEmployeesItems = async () => {
    const allEmployees = await getAllEmployeesChoices()
    const assignedEmployees = []
    const notAssignedEmployees = []

    allEmployees.forEach((employee) => {
      if (employee.disabled) {
        assignedEmployees.push(employee)
      } else {
        notAssignedEmployees.push(employee)
      }
    })

    allEmployeesChoices.value = [
      { header: 'Not assigned' },
      ...notAssignedEmployees,
      { divider: true },
      { header: 'Assigned' },
      ...assignedEmployees,
    ]
  }

  const addPosition = (positions) => {
    positions.push({
      name: '',
      demand: 0,
      assignees: [],
    })
  }

  const deletePosition = (positionIndex, position, positions) => {
    positions.splice(positionIndex, 1)
    // eslint-disable-next-line array-callback-return
    const assignedEmployeesToPosition = position.assignees.map((assignee) => {
      if (assignee.employee !== null) {
        return assignee.employee
      }
    })

    this.allEmployeesChoices.value = this.allEmployeesChoices.value.map((employee) => {
      if (assignedEmployeesToPosition.includes(employee.value)) {
        // eslint-disable-next-line no-param-reassign
        employee.disabled = false
      }

      return employee
    })
  }

  const addAssignee = (assignees) => {
    assignees.push({
      employee: null,
    })
  }

  const updateAssigneeEmployeeValue = (event, assignee) => {
    const oldAssigneeEmployeeValue = _.cloneDeep(assignee.employee)
    // eslint-disable-next-line no-param-reassign
    assignee.employee = event
    if (event !== null) {
      const employeeToUpdate = allEmployeesChoices.value.find((employee) => employee.value === assignee.employee)
      employeeToUpdate.disabled = true
    }

    if (oldAssigneeEmployeeValue !== null) {
      const oldEmployeeToUpdate = allEmployeesChoices.value.find(
        (employee) => employee.value === oldAssigneeEmployeeValue,
      )
      oldEmployeeToUpdate.disabled = false
    }
  }

  const deleteAssignee = (assignees, assignee, assigneeIndex) => {
    assignees.splice(assigneeIndex, 1)
    if (assignee.employee !== null) {
      const employeeToUpdate = allEmployeesChoices.value.find((employee) => employee.value === assignee.employee)
      employeeToUpdate.disabled = false
    }
  }

  const fetchStatusChoices = async () => {
    statusChoices.value = await getClientStatusChoices()
  }

  const fileTypes = ref([])
  const fetchFileTypes = async () => {
    fileTypes.value = await getClientsFileTypes()
  }

  const tagsChoices = ref([])
  const fetchTagChoices = async () => {
    tagsChoices.value = await getClientTags()
  }

  return {
    arrangeAllEmployeesItems,
    addPosition,
    deletePosition,
    addAssignee,
    updateAssigneeEmployeeValue,
    deleteAssignee,
    fetchStatusChoices,

    statusChoices,
    allEmployeesChoices,

    fetchFileTypes,
    fileTypes,

    tagsChoices,
    fetchTagChoices,
  }
}
