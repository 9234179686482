export default function useFileTable() {
  const tableColumns = (handleTypesAndValidUntil, handleReminders, disableUpdate, disableDelete) => {
    return [
      { text: 'name', value: 'name', sortable: true, groupable: false, class: 'text-uppercase', show: true },
      {
        text: 'validUntil',
        value: 'validUntil',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
        show: handleTypesAndValidUntil,
      },
      {
        text: 'reminder',
        value: 'reminder',
        sortable: false,
        groupable: false,
        class: 'text-uppercase',
        show: handleReminders,
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
        sortable: false,
        groupable: false,
        class: 'text-uppercase',
        show: !disableUpdate || !disableDelete,
      },
    ]
  }

  const getFileExtensionFromUrl = (url) => {
    const splittedPathname = new URL(url).pathname.split('.')

    return splittedPathname[splittedPathname.length - 1]
  }

  const reminderChipColor = (remindDateValue) => {
    const remindDate = new Date(remindDateValue)
    const currentDate = new Date()
    const diffTime = remindDate.getTime() - currentDate.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays === 0) {
      return 'warning'
    }
    if (diffDays > 0 && diffDays <= 7) {
      return 'primary'
    }
    if (diffDays < 0) {
      return 'error'
    }

    return 'info'
  }

  return {
    getFileExtensionFromUrl,
    tableColumns,
    reminderChipColor,
  }
}
