<template>
  <reminder-table resource-name="client" :resource-id="clientData.id" @refetch-data="emit('refetch-data')" />
</template>

<script>
import { useState } from 'vuex-composition-helpers'
import ReminderTable from '@/components/reminders-table/ReminderTable.vue'

export default {
  components: { ReminderTable },
  setup(props, { emit }) {
    const { clientData } = useState('staff', ['clientData'])

    return {
      emit,
      clientData,
    }
  },
}
</script>
