import { getCurrentInstance } from '@vue/composition-api'
import { formatDateToMonthShort } from '@core/utils/filter'

export default function assigneeTableUtils() {
  const vm = getCurrentInstance().proxy
  const commonTableColumns = [
    { text: 'from', value: 'dateStart', sortable: true, groupable: false, class: 'text-uppercase' },
    { text: 'to', value: 'dateEnd', sortable: true, groupable: false, class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      groupable: false,
      class: 'text-uppercase',
    },
  ]
  const formatDateEndColumn = (item) => {
    if (!item.dateEnd) return vm.$t('indefinitePeriod')

    return formatDateToMonthShort(item.dateEnd, false)
  }

  return {
    commonTableColumns,
    formatDateEndColumn,
  }
}
