<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('reminders') }}
      <div class="d-flex align-center">
        <v-switch
          v-model="hideDoneReminders"
          hide-details
          class="mt-0"
          :label="$t('hideDoneReminders')"
          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
        />
        <v-btn small color="primary" class="mx-3" @click.stop="isAddNewReminderDialogVisible = true">
          <v-icon class="me-2" left>{{ icons.mdiPlus }}</v-icon>
          <span v-t="'add'" />
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table
      item-key="id"
      show-expand
      :loading="loading"
      :headers="filteredTableColumns"
      :items="reminders"
      :hide-default-footer="true"
      :single-expand="false"
      :items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #[`top`]>
        <v-dialog v-model="isDeleteDialogVisible" max-width="650px" persistent>
          <v-card class="pa-sm-10 pa-3">
            <v-card-title class="justify-center text-h5"> {{ $t('delete') }} </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <v-btn v-t="'discard'" outlined class="me-3" :disabled="deleteLoading" @click="discardDelete" />
                  <v-btn :loading="deleteLoading" color="error" @click="confirmDelete">{{ $t('delete') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isUpdateDialogVisible" persistent max-width="650px" @click:outside="discardUpdate">
          <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-card-title class="justify-center text-h5"> {{ $t('edit') }} </v-card-title>

            <v-card-text class="mt-5">
              <v-form ref="updateReminderForm" class="multi-col-validation" @submit.prevent="confirmUpdate">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="reminderToUpdate.title"
                      outlined
                      dense
                      maxlength="150"
                      :label="$t('title')"
                      :placeholder="$t('title')"
                      :rules="[validators.required]"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reminderToUpdate.remindDate"
                          :label="$t('remindDate')"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          :rules="[validators.required]"
                          hide-details="auto"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reminderToUpdate.remindDate"
                        :first-day-of-week="1"
                        :locale="$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      :value="getFileReminderToUpdate"
                      :label="$t('file')"
                      :placeholder="$t('file')"
                      :items="filesChoices"
                      outlined
                      dense
                      clearable
                      hide-details
                      @input="setFileReminderToUpdate($event)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <upgrade-alert-wrapper>
                      <template #blocked-features>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="reminderToUpdate.owner"
                            :label="$t('assignedTo')"
                            :placeholder="$t('assignedTo')"
                            :items="ownerChoices"
                            outlined
                            dense
                            clearable
                            hide-details
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          ></v-autocomplete>
                        </v-col>
                      </template>
                    </upgrade-alert-wrapper>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn outlined color="secondary" class="me-3" @click.prevent="discardUpdate">
                      {{ $t('discard') }}
                    </v-btn>
                    <v-btn color="primary" type="submit" :loading="updateLoading">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-navigation-drawer
          v-model="isAddNewReminderDialogVisible"
          temporary
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
          app
        >
          <div class="drawer-header d-flex align-center">
            <span v-t="'add'" class="font-weight-semibold text-base text--primary" />
            <v-spacer></v-spacer>
            <v-btn icon small @click="isAddNewReminderDialogVisible = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-form ref="createReminderForm" @submit.prevent="confirmCreate">
            <v-card-text>
              <v-subheader v-t="'reminderData'" />
              <v-text-field
                v-model="newReminderData.title"
                outlined
                dense
                maxlength="150"
                :label="$t('title')"
                :placeholder="$t('title')"
                :rules="[validators.required]"
                hide-details="auto"
                class="mb-3"
              />

              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newReminderData.remindDate"
                    :label="$t('remindDate')"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    class="mb-3"
                    :rules="[validators.required]"
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newReminderData.remindDate"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                ></v-date-picker>
              </v-menu>

              <v-autocomplete
                :value="getFileNewReminder"
                :label="$t('file')"
                :placeholder="$t('file')"
                :items="filesChoices"
                outlined
                dense
                clearable
                hide-details
                class="mb-3"
                @input="setFileNewReminder($event)"
              />

              <upgrade-alert-wrapper :form="false" class="mb-3">
                <template #blocked-features>
                  <v-autocomplete
                    v-model="newReminderData.owner"
                    :label="$t('assignedTo')"
                    :placeholder="$t('assignedTo')"
                    :items="ownerChoices"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="mb-3"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                  />
                </template>
              </upgrade-alert-wrapper>
            </v-card-text>
            <v-card-actions>
              <v-row class="overwritten-drawer-actions">
                <v-col cols="12" md="6">
                  <v-btn color="primary" class="me-3" type="submit" block :loading="createLoading">
                    {{ $t('add') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="discardCreate" />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-navigation-drawer>
      </template>

      <template #[`header.title`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.remindDate`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.isAssignedToFile`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.ownerName`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.doneDate`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.actions`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <td v-if="item.isAssignedToFile" class="text-start">
          <v-btn
            icon
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            @click="expand(!isExpanded)"
          >
            <v-icon>{{ icons.mdiChevronDown }}</v-icon>
          </v-btn>
        </td></template
      >

      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="item.isAssignedToFile" :colspan="headers.length" class="px-0">
          <object
            v-if="getFileExtensionFromUrl(item[`${resourceName}FileUrl`]) === 'pdf'"
            :data="item[`${resourceName}FileUrl`]"
            type="application/pdf"
            class="PDF-Viewer__object w-full"
          />
          <img
            v-else
            :src="item[`${resourceName}FileUrl`]"
            :alt="item[`${resourceName}FileName`]"
            class="Image-Viewer__object w-full"
          />
        </td>
      </template>

      <template #[`item.title`]="{ item }">
        <div :class="item.isDone ? 'text-decoration-line-through' : ''">{{ item.title }}</div>
      </template>

      <template #[`item.validUntil`]="{ item }">
        {{ formatDateToMonthShort(item.validUntil, false) }}
      </template>

      <template #[`item.remindDate`]="{ item }">
        <v-chip small label :color="reminderChipColor(item)">
          <v-icon left>
            {{ icons.mdiBellOutline }}
          </v-icon>
          {{ formatDateToMonthShort(item.remindDate, false) }}
        </v-chip>
      </template>

      <template #[`item.ownerName`]="{ item }">
        {{ emptyValueFormatter(item.ownerName, false) }}
      </template>

      <template #[`item.doneDate`]="{ item }">
        {{ formatDateToMonthShort(item.doneDate) }}
      </template>

      <template #[`item.isAssignedToFile`]="{ item }">
        <v-tooltip v-if="item.isAssignedToFile" bottom>
          <template #activator="tooltipActivator">
            <v-icon v-bind="tooltipActivator.attrs" v-on="tooltipActivator.on">{{
              icons.mdiFileAccountOutline
            }}</v-icon>
          </template>
          <span v-t="item[`${resourceName}FileName`]" />
        </v-tooltip>
        <div v-else>{{ emptyValueFormatter() }}</div>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="tooltipActivator">
              <v-btn
                icon
                small
                color="error"
                v-bind="tooltipActivator.attrs"
                v-on="tooltipActivator.on"
                @click="deleteItem(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'delete'" />
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" small v-bind="attrs" @click="updateReminder(item)" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'edit'" />
          </v-tooltip>

          <v-tooltip v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.isDone ? 'warning' : 'success'"
                small
                v-bind="attrs"
                @click="updateDone(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ item.isDone ? icons.mdiRefresh : icons.mdiCheck }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="item.isDone ? 'unDone' : 'done'" />
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiRefresh,
  mdiPlus,
  mdiPencilOutline,
  mdiBellOutline,
  mdiTrashCanOutline,
  mdiCheck,
  mdiFileAccountOutline,
  mdiChevronDown,
} from '@mdi/js'
import { ref, computed, onMounted, getCurrentInstance, watch } from '@vue/composition-api'

import { emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import { useActions } from 'vuex-composition-helpers'
import useEmployeeReminderTable from './useEmployeeReminderTable'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'
import useFileTable from '@/components/files-table/useFileTable'

export default {
  components: { UpgradeAlertWrapper },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
      validator(value) {
        return ['employee', 'house', 'client', 'project', 'candidateOfferDetail'].includes(value)
      },
    },
  },
  setup(props, { emit }) {
    // Core
    const {
      getResourceReminders: getRemindersAction,
      updateReminder: updateReminderAction,
      deleteReminder: deleteReminderAction,
      createReminder: createReminderAction,
      getFilesChoices,
    } = useActions('staff', [
      'getResourceReminders',
      'updateReminder',
      'deleteReminder',
      'createReminder',
      'getFilesChoices',
    ])
    const { getRecruitersChoices } = useActions('company', ['getRecruitersChoices'])

    const vm = getCurrentInstance().proxy

    const reminders = ref([])
    const loading = ref(false)
    const filesChoices = ref([])
    const recruiters = ref([])
    const hideDoneReminders = ref(true)

    const refetchData = async () => {
      loading.value = true
      reminders.value = await getRemindersAction({
        resourceName: props.resourceName,
        resourceId: props.resourceId,
        hideDoneReminders: hideDoneReminders.value,
      })
      loading.value = false
    }

    onMounted(async () => {
      await refetchData()
    })

    const isReminderGeneratorDialogVisible = ref(false)

    const { tableColumns, reminderChipColor } = useEmployeeReminderTable(vm.$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES))
    const { getFileExtensionFromUrl } = useFileTable()

    const filteredTableColumns = computed(() => {
      return tableColumns(true).filter((column) => column.show)
    })

    // Create
    const createLoading = ref(false)
    const createReminderForm = ref(null)
    const newReminderData = ref({
      title: '',
      remindDate: null,
      owner: null,
      [`${props.resourceName}File`]: null,
      [props.resourceName]: props.resourceId,
    })
    const isAddNewReminderDialogVisible = ref(false)

    const confirmCreate = async () => {
      const isFormValid = createReminderForm.value.validate()
      if (!isFormValid) return

      createLoading.value = true
      const { success } = await createReminderAction({
        resourceName: props.resourceName,
        resourceId: props.resourceId,
        reminderData: newReminderData.value,
      })
      if (success) {
        emit('refetch-data')
        isAddNewReminderDialogVisible.value = false
        await refetchData()
        createReminderForm.value.reset()
        newReminderData.value = {
          title: '',
          remindDate: null,
          owner: null,
          [`${props.resourceName}File`]: null,
          [props.resourceName]: props.resourceId,
        }
      }
      createLoading.value = false
    }
    const discardCreate = () => {
      isAddNewReminderDialogVisible.value = false
      createReminderForm.value.reset()
      newReminderData.value = {
        title: '',
        remindDate: null,
        owner: null,
        [`${props.resourceName}File`]: null,
        [props.resourceName]: props.resourceId,
      }
    }

    const getFileNewReminder = computed(() => {
      return newReminderData.value[`${props.resourceName}File`]
    })
    const setFileNewReminder = (value) => {
      newReminderData.value[`${props.resourceName}File`] = value
    }

    // Delete
    const deleteLoading = ref(false)
    const itemToDeleteId = ref(null)
    const isDeleteDialogVisible = ref(false)

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDeleteDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value === null) return

      deleteLoading.value = true
      const { success } = await deleteReminderAction({
        reminderId: itemToDeleteId.value,
      })
      if (success) {
        emit('refetch-data')
        await refetchData()
        itemToDeleteId.value = null
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDeleteDialogVisible.value = false
    }

    // Update
    const reminderToUpdate = ref(null)
    const updateLoading = ref(false)
    const updateReminderForm = ref(null)
    const isUpdateDialogVisible = ref(false)

    const updateReminder = (reminder) => {
      reminderToUpdate.value = _.cloneDeep(reminder)
      isUpdateDialogVisible.value = true
    }

    const updateHandler = async (updateData) => {
      loading.value = true
      const { data, success } = await updateReminderAction({
        reminderData: updateData,
      })
      if (success) {
        emit('refetch-data')
        reminders.value.splice(_.findIndex(reminders.value, { id: data.id }), 1, data)
        loading.value = false
      }

      return success
    }
    const confirmUpdate = async () => {
      const isFormValid = updateReminderForm.value.validate()
      if (!isFormValid || reminderToUpdate.value === null) return

      updateLoading.value = true

      const success = await updateHandler(reminderToUpdate.value)
      if (success) {
        reminderToUpdate.value = null
        isUpdateDialogVisible.value = false
      }
      updateLoading.value = false
    }

    const updateDone = async (item) => {
      await updateHandler({
        ...item,
        isDone: !item.isDone,
      })
    }
    const discardUpdate = () => {
      isUpdateDialogVisible.value = false
      reminderToUpdate.value = null
    }

    const getFileReminderToUpdate = computed(() => {
      return reminderToUpdate.value[`${props.resourceName}File`]
    })
    const setFileReminderToUpdate = (value) => {
      reminderToUpdate.value[`${props.resourceName}File`] = value
    }

    // --------------
    watch([isUpdateDialogVisible, isAddNewReminderDialogVisible], async ([updateDialogValue, addDialogValue]) => {
      if (updateDialogValue || addDialogValue) {
        filesChoices.value = await getFilesChoices({ resourceName: props.resourceName, resourceId: props.resourceId })
        recruiters.value = await getRecruitersChoices()
      }
    })

    watch(hideDoneReminders, async () => {
      await refetchData()
    })

    const ownerChoices = computed(() => {
      return recruiters.value
        .filter((user) => !['expert', 'suspended'].includes(user.access))
        .map((rec) => {
          return {
            text: `${rec.user.firstName} ${rec.user.lastName}`,
            value: rec.id,
          }
        })
    })

    return {
      // Create
      confirmCreate,
      discardCreate,
      getFileNewReminder,
      setFileNewReminder,
      createLoading,
      createReminderForm,
      isAddNewReminderDialogVisible,
      newReminderData,

      // Delete
      deleteItem,
      confirmDelete,
      discardDelete,
      isDeleteDialogVisible,
      deleteLoading,

      // Update
      updateReminder,
      confirmUpdate,
      updateDone,
      discardUpdate,
      reminderChipColor,
      getFileReminderToUpdate,
      setFileReminderToUpdate,
      reminderToUpdate,
      isUpdateDialogVisible,
      updateLoading,
      updateReminderForm,

      // ---------------

      reminders,
      loading,
      filesChoices,
      ownerChoices,
      hideDoneReminders,
      tableColumns,
      getFileExtensionFromUrl,
      emit,
      emptyValueFormatter,
      formatDateToMonthShort,
      filteredTableColumns,
      isReminderGeneratorDialogVisible,
      validators: {
        required,
      },
      icons: {
        mdiRefresh,
        mdiCheck,
        mdiPlus,
        mdiPencilOutline,
        mdiBellOutline,
        mdiTrashCanOutline,
        mdiFileAccountOutline,
        mdiChevronDown,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
.PDF-Viewer__object {
  height: 70vh;
}

.Image-Viewer__object {
  object-fit: contain;
}
</style>
