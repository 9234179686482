<template>
  <v-card>
    <v-card-text v-if="!disableUpload && handleDocumentGeneration" class="d-flex justify-end align-center">
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="!disableUpload"
          :disabled="loading"
          small
          color="primary"
          class="mb-4 me-3"
          @click.stop="isAddNewDocumentDialogVisible = true"
        >
          <v-icon class="me-2" left>{{ icons.mdiPlus }}</v-icon>
          <span v-t="'addNewFile'" />
        </v-btn>
        <v-btn
          v-if="handleDocumentGeneration"
          :disabled="loading"
          small
          color="primary"
          outlined
          class="mb-4 me-3"
          @click.stop="isDocumentGeneratorDialogVisible = true"
        >
          <v-icon class="me-2" left>{{ icons.mdiFileDocumentOutline }}</v-icon>
          <span>{{ $t('generateFromTemplate') }}</span>
        </v-btn>
      </div>
    </v-card-text>

    <v-data-table
      :loading="loading"
      :headers="filteredTableColumns"
      :items="groupsDisabled ? files : groupedItems"
      :group-by="groupsDisabled ? [] : 'group'"
      sort-by="name"
      :single-expand="false"
      show-expand
      :item-key="groupsDisabled ? 'id' : 'row_id'"
      :hide-default-footer="true"
      :items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #[`top`]>
        <v-dialog v-model="isDeleteDialogVisible" max-width="650px" persistent>
          <v-card class="pa-sm-10 pa-3">
            <v-card-title class="justify-center text-h5"> {{ $t('delete') }} {{ $t('file') }} </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <v-btn v-t="'discard'" outlined class="me-3" :disabled="deleteLoading" @click="discardDelete" />
                  <v-btn :loading="deleteLoading" color="error" @click="confirmDelete">{{ $t('delete') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isUpdateDialogVisible" fullscreen hide-overlay persistent>
          <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-toolbar color="rgba(78, 78, 78, 0.04)" flat>
              <v-btn icon @click="discardUpdate">
                <v-icon class="black--text">{{ icons.mdiClose }}</v-icon>
              </v-btn>
              <v-toolbar-title class="black--text"> {{ $t('edit') }} {{ fileToUpdate.name }} </v-toolbar-title>
              <v-spacer />
              <v-btn
                v-if="handleReminders && !fileToUpdate.reminder"
                v-t="'addReminder'"
                outlined
                color="success"
                class="me-3"
                :disabled="updateLoading"
                @click="addFileReminder(fileToUpdate, reminderTargetObject)"
              />
              <v-btn color="primary" :loading="updateLoading" @click="confirmUpdate">{{ $t('update') }}</v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
              <v-form ref="updateFileForm" class="multi-col-validation" :disabled="updateLoading">
                <v-row align-content="center" justify="center">
                  <v-col cols="12" lg="7">
                    <v-col cols="12">
                      <v-text-field
                        v-model="fileToUpdate.name"
                        :label="$t('name')"
                        :placeholder="$t('name')"
                        outlined
                        dense
                        maxlength="150"
                        :rules="[validators.required]"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col v-if="handleTypesAndValidUntil" cols="12" md="6">
                          <v-combobox
                            v-model="fileToUpdate.types"
                            :items="fileTypes"
                            :label="$t('type')"
                            :placeholder="$t('type')"
                            item-text="type"
                            item-value="id"
                            multiple
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col v-if="handleTypesAndValidUntil" cols="12" md="6">
                          <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fileToUpdate.validUntil"
                                :label="$t('validUntil', { date: '' })"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fileToUpdate.validUntil"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="handleReminders && fileToUpdate.reminder !== null" cols="12" lg="12">
                          <v-card outlined class="card--border-primary">
                            <v-card-title v-t="'reminder'" />
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="fileToUpdate.reminder.title"
                                    :label="$t('title')"
                                    outlined
                                    dense
                                    maxlength="150"
                                    :rules="[validators.required]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-menu
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="fileToUpdate.reminder.remindDate"
                                        :label="$t('remindDate')"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        :rules="[validators.required]"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="fileToUpdate.reminder.remindDate"
                                      :first-day-of-week="1"
                                      :locale="$i18n.locale"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="8">
                                  <v-autocomplete
                                    v-model="fileToUpdate.reminder.owner"
                                    :label="$t('owner')"
                                    :placeholder="$t('owner')"
                                    :items="ownerChoices"
                                    outlined
                                    dense
                                    clearable
                                    hide-details
                                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                  <v-checkbox
                                    v-model="fileToUpdate.reminder.isDone"
                                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                    :ripple="false"
                                    color="primary"
                                    :label="$t('isDone')"
                                    class="mt-0 pt-0"
                                    hide-details
                                  />
                                  <div class="text-xs">
                                    {{ $t('doneOn') }} {{ formatDateToMonthShort(fileToUpdate.reminder.doneDate) }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-tooltip bottom>
                                <template #activator="tooltipActivator">
                                  <v-btn
                                    icon
                                    small
                                    color="error"
                                    v-bind="tooltipActivator.attrs"
                                    v-on="tooltipActivator.on"
                                    @click="deleteFileReminder(fileToUpdate)"
                                  >
                                    <v-icon size="24">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span v-t="'delete'" />
                              </v-tooltip>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <object
                        v-if="getFileExtensionFromUrl(fileToUpdate.file) === 'pdf'"
                        :data="fileToUpdate.file"
                        type="application/pdf"
                        class="PDF-Viewer__object w-full"
                      />
                      <img
                        v-else
                        :src="fileToUpdate.file"
                        :alt="fileToUpdate.name"
                        class="Image-Viewer__object w-full"
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isAddNewDocumentDialogVisible" fullscreen hide-overlay persistent>
          <v-card v-if="isAddNewDocumentDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-toolbar color="rgba(78, 78, 78, 0.04)" flat>
              <v-btn icon @click="discardUpload">
                <v-icon class="black--text">{{ icons.mdiClose }}</v-icon>
              </v-btn>
              <v-toolbar-title class="black--text"> {{ $t('addNewFile') }} </v-toolbar-title>
              <v-spacer />
              <v-btn
                v-if="handleReminders && !fileToUpload.reminder"
                v-t="'addReminder'"
                outlined
                color="success"
                class="me-3"
                :disabled="uploadLoading"
                @click="addFileReminder(fileToUpload, reminderTargetObject)"
              />
              <v-btn :loading="uploadLoading" color="primary" @click="confirmUpload">{{ $t('upload') }}</v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
              <v-form ref="uploadFileForm" class="multi-col-validation" :disabled="uploadLoading">
                <v-row align-content="center" justify="center">
                  <v-col cols="12" lg="7">
                    <v-col cols="12">
                      <file-pond
                        v-model="fileToUpload.file"
                        :label-idle="$t('filePondIdle')"
                        allow-multiple="false"
                        accepted-file-types="application/pdf, image/png, image/jpeg"
                        max-file-size="20MB"
                        max-total-file-size="20MB"
                        @addfile="setUploadedFileName(fileToUpload)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="fileToUpload.name"
                        :label="$t('name')"
                        :placeholder="$t('name')"
                        outlined
                        dense
                        maxlength="150"
                        :rules="[validators.required]"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col v-if="handleTypesAndValidUntil" cols="12" md="6">
                          <v-combobox
                            v-model="fileToUpload.types"
                            :items="fileTypes"
                            :label="$t('type')"
                            :placeholder="$t('type')"
                            item-text="type"
                            item-value="id"
                            multiple
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col v-if="handleTypesAndValidUntil" cols="12" md="6">
                          <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fileToUpload.validUntil"
                                :label="$t('validUntil', { date: '' })"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fileToUpload.validUntil"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="handleReminders && fileToUpload.reminder !== null" cols="12" lg="12">
                          <v-card outlined class="card--border-primary">
                            <v-card-title v-t="'reminder'" />
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="fileToUpload.reminder.title"
                                    :label="$t('title')"
                                    outlined
                                    dense
                                    maxlength="150"
                                    :rules="[validators.required]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-menu
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="fileToUpload.reminder.remindDate"
                                        :label="$t('remindDate')"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        :rules="[validators.required]"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="fileToUpload.reminder.remindDate"
                                      :first-day-of-week="1"
                                      :locale="$i18n.locale"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="8">
                                  <v-autocomplete
                                    v-model="fileToUpload.reminder.owner"
                                    :label="$t('owner')"
                                    :placeholder="$t('owner')"
                                    :items="ownerChoices"
                                    outlined
                                    dense
                                    clearable
                                    hide-details
                                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="4" class="d-flex flex-column justify-center">
                                  <v-checkbox
                                    v-model="fileToUpload.reminder.isDone"
                                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                    :ripple="false"
                                    color="primary"
                                    :label="$t('isDone')"
                                    class="mt-0 pt-0"
                                    hide-details
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-tooltip bottom>
                                <template #activator="tooltipActivator">
                                  <v-btn
                                    icon
                                    small
                                    color="error"
                                    v-bind="tooltipActivator.attrs"
                                    v-on="tooltipActivator.on"
                                    @click="deleteFileReminder(fileToUpload)"
                                  >
                                    <v-icon size="24">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span v-t="'delete'" />
                              </v-tooltip>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <generate-document-from-template-dialog
          v-if="handleDocumentGeneration"
          :is-document-generator-open.sync="isDocumentGeneratorDialogVisible"
          :module="documentGeneratorData.module"
          :file-types="fileTypes"
          :obj="documentGeneratorData.obj"
          :target="documentGeneratorData.target"
          :assignments="documentGeneratorData.assignments"
          :custom-addresses="documentGeneratorData.customAddresses"
          :accommodations="documentGeneratorData.accommodations"
          @refetch-data="emit('refetch-data')"
        />
      </template>

      <template #[`header.name`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.validUntil`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.reminder`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.actions`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template v-if="!groupsDisabled" #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-icon v-if="isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronUpCircleOutline }}</v-icon>
          <v-icon v-else-if="!isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronDownCircleOutline }}</v-icon>
          <span class="font-weight-semibold">{{ group }}</span>
        </td>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <object
            v-if="getFileExtensionFromUrl(item.file) === 'pdf'"
            :data="item.file"
            type="application/pdf"
            class="PDF-Viewer__object w-full"
          />
          <img v-else :src="item.file" :alt="item.name" class="Image-Viewer__object w-full" />
        </td>
      </template>

      <template #[`item.validUntil`]="{ item }">
        {{ formatDateToMonthShort(item.validUntil, false) }}
      </template>

      <template #[`item.reminder`]="{ item }">
        <div v-if="item.reminder === null">-/-</div>
        <v-chip v-else small label :color="reminderChipColor(item.reminder.remindDate)">
          <v-icon left>
            {{ icons.mdiBellOutline }}
          </v-icon>
          {{ formatDateToMonthShort(item.reminder.remindDate, false) }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-tooltip v-if="!disableDelete" bottom>
            <template #activator="tooltipActivator">
              <v-btn
                icon
                small
                color="error"
                v-bind="tooltipActivator.attrs"
                v-on="tooltipActivator.on"
                @click="deleteItem(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'delete'" />
          </v-tooltip>

          <v-tooltip v-if="!disableUpdate" bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" small v-bind="attrs" @click="updateFile(item)" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'edit'" />
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiChevronUpCircleOutline,
  mdiChevronDownCircleOutline,
  mdiBellOutline,
  mdiTrashCanOutline,
  mdiFileDocumentOutline,
} from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'

import { avatarText, emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { uuid } from 'vue-uuid'
import { useActions } from 'vuex-composition-helpers'
import useFileTable from './useFileTable'
import fileUploadUtils from '@/utils/fileUploadUtils'
import GenerateDocumentFromTemplateDialog from '@/components/GenerateDocumentFromTemplateDialog.vue'
import 'filepond/dist/filepond.min.css'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  components: {
    GenerateDocumentFromTemplateDialog,
    FilePond,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    groupsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleReminders: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleTypesAndValidUntil: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    handleDocumentGeneration: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentGeneratorData: {
      type: Object,
      required: false,
      default: () => ({
        module: '',
        target: '',
        obj: null,
        assignments: [],
        customAddresses: [],
        companyAddresses: [],
      }),
    },
    disableUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadFileCallback: {
      type: Function,
      required: false,
      default: () => {},
    },
    disableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteFileCallback: {
      type: Function,
      required: false,
      default: () => {},
    },
    disableUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateFileCallback: {
      type: Function,
      required: false,
      default: () => {},
    },
    fileTargetObject: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    reminderTargetObject: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    // Upload
    const uploadLoading = ref(false)
    const uploadFileForm = ref(null)
    const fileToUpload = ref({
      file: [],
      name: '',
      validUntil: null,
      types: [],
      reminder: null,
    })
    const isAddNewDocumentDialogVisible = ref(false)

    const { setUploadedFileName, addFileReminder, deleteFileReminder } = fileUploadUtils()

    const parseFilesToCreate = (files) => {
      return files
        .filter((file) => !_.isEmpty(file.file[0]))
        .map((file) => {
          return {
            name: file.name,
            file: file.file[0].getFileEncodeBase64String(),

            ...(props.handleTypesAndValidUntil && {
              validUntil: file.validUntil,
              types: file.types.map((fileType) => (_.isPlainObject(fileType) ? fileType : { type: fileType })),
            }),
            ...(props.handleReminders && { reminder: file.reminder }),
          }
        })
    }

    const confirmUpload = async () => {
      const isFormValid = uploadFileForm.value.validate()
      if (!isFormValid || _.isEmpty(fileToUpload.value.file[0])) return
      if (props.disableUpload) return

      uploadLoading.value = true
      const fileToUploadPayload = parseFilesToCreate([fileToUpload.value])
      const { success } = await props.uploadFileCallback({
        ...props.fileTargetObject,
        payload: fileToUploadPayload,
      })
      if (success) {
        emit('refetch-data')
        isAddNewDocumentDialogVisible.value = false
        fileToUpload.value = {
          file: null,
          name: '',
          validUntil: null,
          types: [],
          reminder: null,
        }
      }
      uploadLoading.value = false
    }
    const discardUpload = () => {
      isAddNewDocumentDialogVisible.value = false
      fileToUpload.value = {
        file: null,
        name: '',
        validUntil: null,
        types: [],
        reminder: null,
      }
    }

    // Delete
    const deleteLoading = ref(false)
    const itemToDeleteId = ref(null)
    const isDeleteDialogVisible = ref(false)

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDeleteDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value === null) return
      if (props.disableDelete) return

      deleteLoading.value = true
      const { success } = await props.deleteFileCallback(itemToDeleteId.value)
      if (success) {
        emit('refetch-data')
        itemToDeleteId.value = null
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDeleteDialogVisible.value = false
    }

    // Update
    const fileToUpdate = ref(null)
    const updateLoading = ref(false)
    const updateFileForm = ref(null)
    const isUpdateDialogVisible = ref(false)

    const updateFile = (file) => {
      fileToUpdate.value = _.cloneDeep(file)
      isUpdateDialogVisible.value = true
    }
    const confirmUpdate = async () => {
      const isFormValid = updateFileForm.value.validate()
      if (!isFormValid || fileToUpdate.value === null) return
      if (props.disableUpdate) return

      updateLoading.value = true

      const keysToOmit = ['group', 'row_id']
      if (!props.handleTypesAndValidUntil) keysToOmit.push('types', 'validUntil')
      else
        fileToUpdate.value.types = fileToUpdate?.value?.types?.map((fileType) =>
          _.isPlainObject(fileType) ? fileType : { type: fileType },
        )

      if (!props.handleReminders) keysToOmit.push('reminder')

      fileToUpdate.value = _.omit(fileToUpdate.value, keysToOmit)

      const { success } = await props.updateFileCallback(fileToUpdate.value)
      if (success) {
        emit('refetch-data')
        fileToUpdate.value = null
      }
      updateLoading.value = false
      isUpdateDialogVisible.value = false
    }
    const discardUpdate = () => {
      isUpdateDialogVisible.value = false
      fileToUpdate.value = null
    }

    // ---------------

    const isDocumentGeneratorDialogVisible = ref(false)

    const { getFileExtensionFromUrl, tableColumns, reminderChipColor } = useFileTable()

    const filteredTableColumns = computed(() => {
      return tableColumns(
        props.handleTypesAndValidUntil,
        props.handleReminders,
        props.disableUpdate,
        props.disableDelete,
      ).filter((column) => column.show)
    })

    const localUuid = uuid

    const groupedItems = computed(() => {
      const flattenedItemsArray = []

      props.files.forEach((item) => {
        if (item.types.length === 0) {
          flattenedItemsArray.push({
            ...item,
            row_id: `${item.name}_without_type_${localUuid.v4()}`,
            group: 'Without type',
          })
        } else {
          item.types.forEach((type) => {
            flattenedItemsArray.push({
              ...item,
              row_id: `${item.name}_${type.type}_${localUuid.v4()}`,
              group: type.type,
            })
          })
        }
      })

      return flattenedItemsArray
    })

    const { getRecruitersChoices } = useActions('company', ['getRecruitersChoices'])
    const recruiters = ref([])

    watch(isUpdateDialogVisible, async (value) => {
      if (value) recruiters.value = await getRecruitersChoices()
    })

    const ownerChoices = computed(() => {
      return recruiters.value
        .filter((user) => !['expert', 'suspended'].includes(user.access))
        .map((rec) => {
          return {
            text: `${rec.user.firstName} ${rec.user.lastName}`,
            value: rec.id,
          }
        })
    })

    return {
      // Upload
      getFileExtensionFromUrl,
      setUploadedFileName,
      addFileReminder,
      confirmUpload,
      discardUpload,
      uploadLoading,
      uploadFileForm,
      isAddNewDocumentDialogVisible,
      fileToUpload,

      // Delete
      deleteItem,
      confirmDelete,
      discardDelete,
      isDeleteDialogVisible,
      deleteLoading,

      // Update
      updateFile,
      confirmUpdate,
      discardUpdate,
      reminderChipColor,
      fileToUpdate,
      isUpdateDialogVisible,
      updateLoading,
      updateFileForm,

      // ---------------

      deleteFileReminder,
      tableColumns,
      emit,
      avatarText,
      emptyValueFormatter,
      formatDateToMonthShort,
      groupedItems,
      filteredTableColumns,
      isDocumentGeneratorDialogVisible,
      ownerChoices,
      validators: {
        required,
      },
      icons: {
        mdiClose,
        mdiPlus,
        mdiPencilOutline,
        mdiChevronUpCircleOutline,
        mdiChevronDownCircleOutline,
        mdiBellOutline,
        mdiTrashCanOutline,
        mdiFileDocumentOutline,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.PDF-Viewer__object {
  height: 70vh;
}

.Image-Viewer__object {
  object-fit: contain;
}
</style>
