<template>
  <div :id="element">
    <ul class="attribute d-none">
      <li data-value="{First Name}">{First Name}</li>
      <li data-value="{Last Name}">{Last Name}</li>
      <li data-value="{Company Name}">{Company Name}</li>
      <li data-value="{Email}">{Email}</li>
      <li data-value="{Address}">{Address}</li>
      <li data-value="{City}">{City}</li>
      <li data-value="{State}">{State}</li>
    </ul>
  </div>
</template>

<script>
import 'summernote/dist/summernote-lite.min'
import sanitizeHtml from 'sanitize-html'
import './plugin/table/summernote-ext-table.css'
import './plugin/table/summernote-ext-table'
import _ from 'lodash'

const $ = require('jquery')

export default {
  name: 'Summernote',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideLinkAndImageButton: {
      type: Boolean,
      default: false,
    },
    emailVariables: {
      type: Array,
      default() {
        return [
          [this.$t('candidateFirstName'), '{{ candidate.firstName }}'],
          [this.$t('candidateLastName'), '{{ candidate.lastName }}'],
          [this.$t('candidateStepFrom'), '{{ candidate.step.from }}'],
          [this.$t('candidateStepTo'), '{{ candidate.step.to }}'],
          [this.$t('jobTitle'), '{{ job.title }}'],
          [this.$t('jobDescription'), '{{ job.description }}'],
          [this.$t('recruiterFirstName'), '{{ recruiter.firstName }}'],
          [this.$t('recruiterLastName'), '{{ recruiter.lastName }}'],
          [this.$t('recruiterEmail'), '{{ recruiter.email }}'],
          [this.$t('companyName'), '{{ company.name }}'],
          [this.$t('companyRegistrationName'), '{{ company.registrationName }}'],
        ]
      },
    },
    customFieldsVariables: {
      type: Array,
      required: false,
      default: () => [],
    },
    height: {
      type: Number,
      required: false,
      default: () => 400,
    },
  },
  data() {
    return {
      codeViewActive: false,
      element: `summernote_${new Date().getTime()}`,
    }
  },
  computed: {
    mapVariablesToSuggestions() {
      return this.emailVariables.map(([label, variable]) => {
        return {
          display: label,
          value: variable.replace('{{', '').replace('}}', ''),
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      const defaultConfig = {
        tabsize: 2,
        height: this.height,
        width: '100%',
        codemirror: {
          mode: 'text/html',
          theme: 'ambiance',
        },
        disableResizeEditor: true,
        focus: false,
        dialogsInBody: true,
        dialogsFade: true,
        tableClassName() {
          $(this).removeClass('table table-bordered').css({ 'border-collapse': 'collapse', width: '100%' })
          $(this).find('td', 'th').css({
            border: '1px solid black',
          })
        },
        toolbar: [
          ['view', ['codeview', 'undo', 'redo']],
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontsize', ['fontname', 'fontsize', 'forecolor', 'backcolor']],
          ['para', ['ul', 'ol', 'paragraph', 'style', 'height']],
          this.hideLinkAndImageButton ? ['insert', ['hr', 'table']] : ['insert', ['hr', 'link', 'picture']],
          ['emailTemplatesVariables', ['emailTemplatesVariables']],
          !_.isEmpty(this.customFieldsVariables)
            ? ['customFieldsTemplateVariables', ['customFieldsTemplateVariables']]
            : null,
        ],
        jTable: {
          mergeMode: 'drag',
        },
        buttons: {
          emailTemplatesVariables: (context) => this.emailTemplatesVariables(context),
          customFieldsTemplateVariables: (context) => this.customFieldsTemplateVariables(context),
        },
        styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        popover: {
          image: [
            ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter']],
            ['float', ['floatLeft', 'floatRight', 'floatNone']],
            ['remove', ['removeMedia']],
          ],
          link: [['link', ['linkDialogShow', 'unlink']]],
          table: [
            ['merge', ['jMerge']],
            ['style', ['jBackcolor', 'jBorderColor', 'jAlign', 'jAddDeleteRowCol']],
            ['info', ['jTableInfo']],
            ['delete', ['jWidthHeightReset', 'deleteTable']],
          ],
        },
        disableDragAndDrop: true,
        codeviewFilter: false,
        codeviewIframeFilter: true,
        callbacks: {
          onInit: () => {
            this.$emit('ready', this)
          },
          onChange: (contents) => {
            const sanitizedHtml = sanitizeHtml(contents, {
              allowedTags: [
                'address',
                'article',
                'aside',
                'footer',
                'header',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'hgroup',
                'main',
                'nav',
                'section',
                'blockquote',
                'dd',
                'div',
                'dl',
                'dt',
                'figcaption',
                'figure',
                'hr',
                'li',
                'main',
                'ol',
                'p',
                'pre',
                'ul',
                'a',
                'abbr',
                'b',
                'bdi',
                'bdo',
                'br',
                'cite',
                'code',
                'data',
                'dfn',
                'em',
                'i',
                'kbd',
                'mark',
                'q',
                'rb',
                'rp',
                'rt',
                'rtc',
                'ruby',
                's',
                'samp',
                'img',
                'small',
                'span',
                'strong',
                'sub',
                'sup',
                'time',
                'u',
                'var',
                'wbr',
                'caption',
                'col',
                'colgroup',
                'table',
                'tbody',
                'td',
                'tfoot',
                'th',
                'thead',
                'tr',
                'summary',
                'details',
                'svg',
                'audio',
                'video',
                'del',
                'strike',
                'ins',
                'path',
              ],
              allowedAttributes: {
                '*': [
                  'data-*',
                  'cell*',
                  'contenteditable',
                  'colspan',
                  'rowspan',
                  'target',
                  'href',
                  'rel',
                  'src',
                  'alt',
                  'class',
                  'controls',
                  'style',
                  'width',
                  'bgcolor',
                  'role',
                  'origin-size',
                  'align',
                  'valign',
                  'border',
                ],
              },
              transformTags: {
                font(tagName, attrs) {
                  return {
                    tagName: 'span',
                    attribs: {
                      style: `color: ${attrs.color};`,
                    },
                  }
                },
              },
            })
            if (this.codeViewActive) {
              this.codeViewActive = false
              this.summernote('code', sanitizedHtml)

              return
            }
            this.$emit('change', sanitizedHtml)
          },
          onEnter: () => {
            this.$emit('enter')
          },
          onFocus: () => {
            this.$emit('focus')
          },
          onBlur: () => {
            this.$emit('blur')
          },
          onKeyup: (e) => {
            this.$emit('keyup', e)
          },
          onKeydown: (e) => {
            this.$emit('keydown', e)
          },
          onPaste: (e) => {
            this.$emit('paste', e)
          },
          onChangeCodeview: (e) => {
            this.codeViewActive = true
            this.$emit('changeCodeview', e)
          },
          onImageLinkInsert: (url) => {
            const img = $('<img>').attr({ src: url, style: 'width: 100%' })
            this.summernote('insertNode', img[0])
          },
        },
      }
      const config = { ...defaultConfig, ...this.config }
      $(`#${this.element}`).summernote(config)
      if (this.disabled) {
        this.summernote('disable')
      }
    })
  },
  methods: {
    summernote(...args) {
      $(`#${this.element}`).summernote(...args)
    },
    ui() {
      return $.summernote.ui
    },
    emailTemplatesVariables(context) {
      const keywordMap = new Map(this.emailVariables)
      const list = Array.from(keywordMap.keys())

      return this.ui()
        .buttonGroup([
          this.ui().button({
            className: 'dropdown-toggle',
            contents: `<span>${this.$t('addVariable')}</span><span class="note-icon-caret"></span>`,
            data: {
              toggle: 'dropdown',
            },
          }),
          this.ui().dropdown({
            items: list,
            className: 'dropdown-email-control',
            click(event) {
              const $button = $(event.target)
              $button.removeAttr('href')
              const value = $button.data('value')
              context.invoke('editor.insertText', keywordMap.get(value))
            },
          }),
        ])
        .render()
    },
    customFieldsTemplateVariables(context) {
      const keywordMap = new Map(this.customFieldsVariables)
      const list = Array.from(keywordMap.keys())

      return this.ui()
        .buttonGroup([
          this.ui().button({
            className: 'dropdown-toggle',
            contents: `<span>${this.$t('customFieldsVariables')}</span><span class="note-icon-caret"></span>`,
            data: {
              toggle: 'dropdown',
            },
          }),
          this.ui().dropdown({
            items: list,
            className: 'dropdown-email-control',
            click(event) {
              const $button = $(event.target)
              $button.removeAttr('href')
              const value = $button.data('value')
              context.invoke('editor.insertText', keywordMap.get(value))
            },
          }),
        ])
        .render()
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'plugin/table/summernote-ext-table.css';
</style>
