// Data
export const TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA = (instance) => [
  {
    variable: 'candidate.firstName',
    label: instance.$t('candidateFirstName'),
    maxLength: '150',
    testValue: '',
  },
  {
    variable: 'candidate.lastName',
    label: instance.$t('candidateLastName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'candidate.step.from',
    label: instance.$t('candidateStepFrom'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'candidate.step.to',
    label: instance.$t('candidateStepTo'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'job.title',
    label: instance.$t('jobTitle'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'job.description',
    label: instance.$t('jobDescription'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'recruiter.firstName',
    label: instance.$t('recruiterFirstName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'recruiter.lastName',
    label: instance.$t('recruiterLastName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'recruiter.email',
    label: instance.$t('recruiterEmail'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'company.name',
    label: instance.$t('companyName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'company.registrationName',
    label: instance.$t('companyRegistrationName'),
    maxLength: 150,
    testValue: '',
  },
]

const TEST_EMAIL_VARIABLES_TALENT_POOL_BASE_DATA = (instance) => [
  {
    variable: 'candidate.firstName',
    label: instance.$t('candidateFirstName'),
    maxLength: '150',
    testValue: '',
  },
  {
    variable: 'candidate.lastName',
    label: instance.$t('candidateLastName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'company.name',
    label: instance.$t('companyName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'company.registrationName',
    label: instance.$t('companyRegistrationName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'company.talent_pool.consent_retention_in_months',
    label: instance.$t('talentPoolRetention'),
    maxLength: 150,
    testValue: '',
  },
]
export const TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA = (instance) => [
  ...TEST_EMAIL_VARIABLES_TALENT_POOL_BASE_DATA(instance),
  {
    variable: 'renew_consent_url',
    label: instance.$t('renewConsentUrl'),
    maxLength: 150,
    testValue: '',
  },
]
export const TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA = (instance) => [
  ...TEST_EMAIL_VARIABLES_TALENT_POOL_BASE_DATA(instance),
  {
    variable: 'invite_job.title',
    label: instance.$t('inviteJobTitle'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'invite_job.assigned_to.firstName',
    label: instance.$t('inviteJobRecruiterName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'invite_job.assigned_to.lastName',
    label: instance.$t('inviteJobRecruiterLastName'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'invite_job.assigned_to.email',
    label: instance.$t('inviteJobRecruiterEmail'),
    maxLength: 150,
    testValue: '',
  },
  {
    variable: 'invite_to_job_url',
    label: instance.$t('inviteJobUrl'),
    maxLength: 150,
    testValue: '',
  },
]

// Summer Note
export const EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE = (instance) => [
  [instance.$t('candidateFirstName'), '{{ candidate.firstName }}'],
  [instance.$t('candidateLastName'), '{{ candidate.lastName }}'],
  [instance.$t('candidateStepFrom'), '{{ candidate.step.from }}'],
  [instance.$t('candidateStepTo'), '{{ candidate.step.to }}'],
  [instance.$t('jobTitle'), '{{ job.title }}'],
  [instance.$t('jobDescription'), '{{ job.description }}'],
  [instance.$t('recruiterFirstName'), '{{ recruiter.firstName }}'],
  [instance.$t('recruiterLastName'), '{{ recruiter.lastName }}'],
  [instance.$t('recruiterEmail'), '{{ recruiter.email }}'],
  [instance.$t('companyName'), '{{ company.name }}'],
  [instance.$t('companyRegistrationName'), '{{ company.registrationName }}'],
]

const EMAIL_VARIABLES_TALENT_POOL_BASE_SUMMERNOTE = (instance) => [
  [instance.$t('candidateFirstName'), '{{ candidate.firstName }}'],
  [instance.$t('candidateLastName'), '{{ candidate.lastName }}'],
  [instance.$t('companyName'), '{{ company.name }}'],
  [instance.$t('companyRegistrationName'), '{{ company.registrationName }}'],
  [instance.$t('talentPoolRetention'), '{{ company.talent_pool.consent_retention_in_months }}'],
]
export const EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_SUMMERNOTE = (instance) => [
  ...EMAIL_VARIABLES_TALENT_POOL_BASE_SUMMERNOTE(instance),
  [instance.$t('renewConsentUrl'), '{{ renew_consent_url }}'],
]
export const EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE = (instance) => [
  ...EMAIL_VARIABLES_TALENT_POOL_BASE_SUMMERNOTE(instance),
  [instance.$t('inviteJobTitle'), '{{ invite_job.title }}'],
  [instance.$t('inviteJobRecruiterName'), '{{ invite_job.assigned_to.firstName }}'],
  [instance.$t('inviteJobRecruiterLastName'), '{{ invite_job.assigned_to.lastName }}'],
  [instance.$t('inviteJobRecruiterEmail'), '{{ invite_job.assigned_to.email }}'],
  [instance.$t('inviteJobUrl'), '{{ invite_to_job_url }}'],
]

export const DOCUMENT_COMPANY_VARIABLES = (instance) => [
  [instance.$t('companyName'), '{{ company.name }}'],
  [instance.$t('companyRegistrationName'), '{{ company.registration_name }}'],
  [instance.$t('companyTaxId'), '{{ company.tax_id }}'],
  [instance.$t('companyContactEmail'), '{{ company.contact_email }}'],
  [instance.$t('companyStreet'), '{{ company.street }}'],
  [instance.$t('companyHouseNumber'), '{{ company.house_number }}'],
  [instance.$t('companyFlatNumber'), '{{ company.flat_number }}'],
  [instance.$t('companyCity'), '{{ company.city }}'],
  [instance.$t('companyZipCode'), '{{ company.zip_code }}'],
  [instance.$t('companyState'), '{{ company.state }}'],
  [instance.$t('companyCountry'), '{{ company.country }}'],
]

export const DOCUMENT_EMPLOYEES_VARIABLES = (instance) => [
  [instance.$t('employeeFirstName'), '{{ employee.first_name }}'],
  [instance.$t('employeeLastName'), '{{ employee.last_name }}'],
  [instance.$t('employeeStatus'), '{{ employee.status }}'],
  [instance.$t('employeeEmail'), '{{ employee.email }}'],
  [instance.$t('employeePhoneNumber'), '{{ employee.phone_number }}'],
  [instance.$t('employeeStartWorkingDate'), '{{ employee.start_working_date }}'],
  [instance.$t('employeeMartialStatus'), '{{ employee.marital_status }}'],
  [instance.$t('employeeNationality'), '{{ employee.nationality }}'],
  [instance.$t('employeePosition'), '{{ employee.position }}'],
  [instance.$t('employeeProject'), '{{ employee.project.name }}'],
  [instance.$t('employeeClientName'), '{{ employee.client.name }}'],
  [instance.$t('employeeClientRegistrationName'), '{{ employee.client.registration_name }}'],
  [instance.$t('employeeClientStreet'), '{{ employee.client.street }}'],
  [instance.$t('employeeClientHouseNumber'), '{{ employee.client.house_number }}'],
  [instance.$t('employeeClientFlatNumber'), '{{ employee.client.flat_number }}'],
  [instance.$t('employeeClientCity'), '{{ employee.client.city }}'],
  [instance.$t('employeeClientZipCode'), '{{ employee.client.zip_code }}'],
  [instance.$t('employeeClientState'), '{{ employee.client.state }}'],
  [instance.$t('employeeClientCountry'), '{{ employee.client.country }}'],
  [instance.$t('employeeProjectAssignmentDateStart'), '{{ employee.project_assignment_date_start }}'],
  [instance.$t('employeeProjectAssignmentDateEnd'), '{{ employee.project_assignment_date_end }}'],

  [instance.$t('employeeCustomAddressName'), '{{ employee.custom_address.name }}'],
  [instance.$t('employeeCustomAddressStreet'), '{{ employee.custom_address.street }}'],
  [instance.$t('employeeCustomAddressHouseNumber'), '{{ employee.custom_address.house_number }}'],
  [instance.$t('employeeCustomAddressFlatNumber'), '{{ employee.custom_address.flat_number }}'],
  [instance.$t('employeeCustomAddressCity'), '{{ employee.custom_address.city }}'],
  [instance.$t('employeeCustomAddressZipCode'), '{{ employee.custom_address.zip_code }}'],
  [instance.$t('employeeCustomAddressState'), '{{ employee.custom_address.state }}'],
  [instance.$t('employeeCustomAddressCountry'), '{{ employee.custom_address.country }}'],
  [instance.$t('employeeCustomAddressDateStart'), '{{ employee.custom_address.date_start }}'],
  [instance.$t('employeeCustomAddressDateEnd'), '{{ employee.custom_address.date_end }}'],

  [instance.$t('employeeCompanyAddressName'), '{{ employee.company_address.name }}'],
  [instance.$t('employeeCompanyAddressStreet'), '{{ employee.company_address.street }}'],
  [instance.$t('employeeCompanyAddressHouseNumber'), '{{ employee.company_address.house_number }}'],
  [instance.$t('employeeCompanyAddressFlatNumber'), '{{ employee.company_address.flat_number }}'],
  [instance.$t('employeeCompanyAddressCity'), '{{ employee.company_address.city }}'],
  [instance.$t('employeeCompanyAddressZipCode'), '{{ employee.company_address.zip_code }}'],
  [instance.$t('employeeCompanyAddressState'), '{{ employee.company_address.state }}'],
  [instance.$t('employeeCompanyAddressCountry'), '{{ employee.company_address.country }}'],
  [instance.$t('employeeCompanyAddressDateStart'), '{{ employee.company_address.date_start }}'],
  [instance.$t('employeeCompanyAddressDateEnd'), '{{ employee.company_address.date_end }}'],

  [instance.$t('employeeId'), '{{ employee.id }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

export const DOCUMENT_HOUSES_VARIABLES = (instance) => [
  [instance.$t('houseName'), '{{ house.name }}'],
  [instance.$t('houseStatus'), '{{ house.status }}'],
  [instance.$t('houseStreet'), '{{ house.street }}'],
  [instance.$t('houseHouseNumber'), '{{ house.house_number }}'],
  [instance.$t('houseFlatNumber'), '{{ house.flat_number }}'],
  [instance.$t('houseCity'), '{{ house.city }}'],
  [instance.$t('houseZipCode'), '{{ house.zip_code }}'],
  [instance.$t('houseState'), '{{ house.state }}'],
  [instance.$t('houseCountry'), '{{ house.country }}'],
  [instance.$t('houseRoomsList'), '{{ house.rooms_list }}'],
  [instance.$t('houseTotalPlaces'), '{{ house.total_places }}'],
  [instance.$t('houseFreePlaces'), '{{ house.free_places }}'],
  [instance.$t('houseTakenPlaces'), '{{ house.taken_places }}'],
  [instance.$t('houseOccupantsList'), '{{ house.occupants_list }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

export const DOCUMENT_HOUSES_COMMON_VARIABLES = (instance) => [
  [instance.$t('houseName'), '{{ house.name }}'],
  [instance.$t('houseStatus'), '{{ house.status }}'],
  [instance.$t('houseStreet'), '{{ house.street }}'],
  [instance.$t('houseHouseNumber'), '{{ house.house_number }}'],
  [instance.$t('houseFlatNumber'), '{{ house.flat_number }}'],
  [instance.$t('houseCity'), '{{ house.city }}'],
  [instance.$t('houseZipCode'), '{{ house.zip_code }}'],
  [instance.$t('houseState'), '{{ house.state }}'],
  [instance.$t('houseCountry'), '{{ house.country }}'],
  [instance.$t('houseRoomsList'), '{{ house.rooms_list }}'],
  [instance.$t('houseTotalPlaces'), '{{ house.total_places }}'],
  [instance.$t('houseFreePlaces'), '{{ house.free_places }}'],
  [instance.$t('houseTakenPlaces'), '{{ house.taken_places }}'],
]

export const DOCUMENT_HOUSES_SHINE_VARIABLES = (instance) => [
  ...DOCUMENT_HOUSES_COMMON_VARIABLES(instance),
  [instance.$t('houseOccupantsCurrentList'), '{{ house.occupants_current_list }}'],
  [instance.$t('houseOccupantsFutureList'), '{{ house.occupants_future_list }}'],
  [instance.$t('houseOccupantsPastList'), '{{ house.occupants_past_list }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

const DOCUMENT_PROJECTS_COMMON_VARIABLES = (instance) => [
  [instance.$t('projectName'), '{{ project.name }}'],
  [instance.$t('projectStatus'), '{{ project.status }}'],
  [instance.$t('clientStreet'), '{{ project.client.street }}'],
  [instance.$t('clientHouseNumber'), '{{ project.client.house_number }}'],
  [instance.$t('clientFlatNumber'), '{{ project.client.flat_number }}'],
  [instance.$t('clientCity'), '{{ project.client.city }}'],
  [instance.$t('clientZipCode'), '{{ project.client.zip_code }}'],
  [instance.$t('clientState'), '{{ project.client.state }}'],
  [instance.$t('clientCountry'), '{{ project.client.country }}'],
  [instance.$t('totalPositions'), '{{ project.total_positions }}'],
  [instance.$t('positionsList'), '{{ project.positions_list }}'],
  [instance.$t('totalDemand'), '{{ project.total_demand }}'],
  [instance.$t('takenAssignees'), '{{ project.taken_assignees }}'],
  [instance.$t('missingAssignees'), '{{ project.missing_assignees }}'],
]

export const DOCUMENT_PROJECTS_SHINE_VARIABLES = (instance) => [
  ...DOCUMENT_PROJECTS_COMMON_VARIABLES(instance),
  [instance.$t('assigneesCurrentList'), '{{ project.assignees_current_list }}'],
  [instance.$t('assigneesFutureList'), '{{ project.assignees_future_list }}'],
  [instance.$t('assigneesPastList'), '{{ project.assignees_past_list }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

const DOCUMENT_CLIENTS_COMMON_VARIABLES = (instance) => [
  [instance.$t('clientName'), '{{ client.name }}'],
  [instance.$t('clientRegistrationName'), '{{ client.registration_name }}'],
  [instance.$t('clientStatus'), '{{ client.status }}'],
  [instance.$t('clientStreet'), '{{ client.street }}'],
  [instance.$t('clientHouseNumber'), '{{ client.house_number }}'],
  [instance.$t('clientFlatNumber'), '{{ client.flat_number }}'],
  [instance.$t('clientCity'), '{{ client.city }}'],
  [instance.$t('clientZipCode'), '{{ client.zip_code }}'],
  [instance.$t('clientState'), '{{ client.state }}'],
  [instance.$t('clientCountry'), '{{ client.country }}'],
  [instance.$t('totalProjects'), '{{ client.total_projects }}'],
  [instance.$t('clientProjectsList'), '{{ client.projects_list }}'],
  [instance.$t('totalPositions'), '{{ client.total_positions }}'],
  [instance.$t('positionsList'), '{{ client.positions_list }}'],
  [instance.$t('totalDemand'), '{{ client.total_demand }}'],
  [instance.$t('takenAssignees'), '{{ client.taken_assignees }}'],
  [instance.$t('missingAssignees'), '{{ client.missing_assignees }}'],
]

export const DOCUMENT_CLIENTS_SHINE_VARIABLES = (instance) => [
  ...DOCUMENT_CLIENTS_COMMON_VARIABLES(instance),
  [instance.$t('assigneesCurrentList'), '{{ client.assignees_current_list }}'],
  [instance.$t('assigneesFutureList'), '{{ client.assignees_future_list }}'],
  [instance.$t('assigneesPastList'), '{{ client.assignees_past_list }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

export const DOCUMENT_CANDIDATES_VARIABLES = (instance) => [
  [instance.$t('candidateFirstName'), '{{ candidate.first_name }}'],
  [instance.$t('candidateLastName'), '{{ candidate.last_name }}'],
  [instance.$t('candidateEmail'), '{{ candidate.email }}'],
  [instance.$t('candidatePhoneNumber'), '{{ candidate.phone_number }}'],
  [instance.$t('candidateJobTitle'), '{{ candidate.job_title }}'],
  [instance.$t('candidateCurrentStep'), '{{ candidate.current_step }}'],
  ...DOCUMENT_COMPANY_VARIABLES(instance),
]

const moduleNameMap = {
  employees: 'employee',
  houses: 'house',
  projects: 'project',
  clients: 'client',
  candidates: 'candidate',
}

const constructFieldName = (variable, customFieldSlug) => `{{ ${variable}.custom_field.${customFieldSlug} }}`

export const parseCustomFieldsToVariables = (customFields, module) =>
  customFields.map((customField) => [customField.name, constructFieldName(moduleNameMap[module], customField.slug)])

export const mapNestedModuleCustomFieldsToVariables = (customFields, parentModule, nestedModule, instance) =>
  customFields.map((customField) => [
    `${instance.$t(moduleNameMap[nestedModule])} - ${customField.name}`,
    constructFieldName(`${moduleNameMap[parentModule]}.${moduleNameMap[nestedModule]}`, customField.slug),
  ])
