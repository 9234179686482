import _ from 'lodash'
import router from '@/router'

export default function navigationUtils() {
  const canGoToNext = (ids, currentId) => {
    const lastElement = _.last(ids)

    return lastElement && lastElement !== currentId
  }

  const goToNext = async (ids, currentId, loading, routerParams) => {
    if (!canGoToNext(ids, currentId) || loading) return
    const currentIndex = _.indexOf(ids, currentId)
    await router.push({
      name: routerParams.name,
      params: {
        [routerParams.param]: ids[currentIndex + 1],
      },
    })
  }

  const canGoToPrevious = (ids, currentId) => {
    const firstElement = _.head(ids)

    return firstElement && firstElement !== currentId
  }

  const goToPrevious = async (ids, currentId, loading, routerParams) => {
    if (!canGoToPrevious(ids, currentId) || loading) return
    const currentIndex = _.indexOf(ids, currentId)
    await router.push({
      name: routerParams.name,
      params: {
        [routerParams.param]: ids[currentIndex - 1],
      },
    })
  }

  return {
    canGoToNext,
    goToNext,

    canGoToPrevious,
    goToPrevious,
  }
}
