import { getCurrentInstance } from '@vue/composition-api'

export default function documentTemplateUtils() {
  const vm = getCurrentInstance().proxy

  const MODULE_EMPLOYEES = 'employees'
  const MODULE_HOUSES = 'houses'
  const MODULE_PROJECTS = 'projects'
  const MODULE_CLIENTS = 'clients'
  const MODULE_CANDIDATES = 'candidates'

  const defaultChoices = {
    moduleChoices: [
      {
        text: vm.$t('employees'),
        value: MODULE_EMPLOYEES,
      },
      {
        text: vm.$t('houses'),
        value: MODULE_HOUSES,
      },
      {
        text: vm.$t('projects'),
        value: MODULE_PROJECTS,
      },
      {
        text: vm.$t('clients'),
        value: MODULE_CLIENTS,
      },
      {
        text: vm.$t('candidates'),
        value: MODULE_CANDIDATES,
      },
    ],
  }

  return {
    MODULE_EMPLOYEES,
    MODULE_HOUSES,
    MODULE_PROJECTS,
    MODULE_CLIENTS,
    MODULE_CANDIDATES,

    defaultChoices,
  }
}
