<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title>Projects</v-card-title>
      <v-card-text>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(project, index) in clientData.projects" :key="index">
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters class="align-center">
                <v-col cols="4"
                  >{{ project.name
                  }}<v-icon color="primary" class="ml-2" small @click.stop="goToProjectDetailsPage(project.id)">{{
                    icons.mdiOpenInNew
                  }}</v-icon></v-col
                >
                <v-col cols="8" class="text--secondary">
                  <v-fade-transition leave-absolute>
                    <v-row v-if="open" no-gutters class="align-center">
                      <v-col cols="6">
                        <v-chip
                          label
                          small
                          color="primary"
                          class="v-chip-light-bg primary--text font-weight-semibold text-capitalize"
                          >{{ projectStatusText(project) }}</v-chip
                        >
                      </v-col>
                      <v-col cols="6"
                        >{{ project.allAssignees }} / {{ project.allPositionsDemand }} ({{ project.workload }}%)</v-col
                      >
                    </v-row>
                    <v-row v-else no-gutters style="width: 100%">
                      <v-col cols="6">{{ project.positions.length }} {{ $t('positions') }}</v-col>
                      <v-col cols="6">{{ project.allAssignees }} / {{ project.allPositionsDemand }}</v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col v-for="(position, index) in project.positions" :key="`Position--${index}`" cols="12">
                  <v-card outlined class="card--border-primary">
                    <v-card-title>
                      <v-row align-content="center" justify="space-between">
                        <v-col cols="9" sm="10">
                          {{ position.name }}
                        </v-col>
                        <v-col cols="auto" class="text-sm">
                          {{ position.allAssigneesCount }} / {{ position.demand }}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          v-for="(assignee, assigneeIndex) in position.currentAssignees"
                          :key="`Assignee--${assignee.id}-${assigneeIndex}`"
                          cols="auto"
                        >
                          <v-chip
                            label
                            small
                            color="info"
                            :to="{ name: 'apps-employee-view', params: { id: assignee.employee } }"
                            class="v-chip-light-bg info--text font-weight-semibold text-capitalize ma-1 mb-2"
                            ><span>{{ assignee.firstName }} {{ assignee.lastName }}</span>
                            <v-icon color="info" right>{{ icons.mdiChevronRight }}</v-icon></v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-row align-content="center">
                        <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                          <span class="font-weight-bold me-2">{{ $t('futureCount') }}:</span>
                          <span class="text--secondary">{{ position.futureCount }}</span>
                        </v-col>
                        <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                          <span class="font-weight-bold me-2">{{ $t('pastCount') }}:</span>
                          <span class="text--secondary">{{ position.pastCount }}</span>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                          <v-btn
                            right
                            small
                            depressed
                            color="primary"
                            :to="{ name: 'apps-project-position-view', params: { id: position.id } }"
                          >
                            <span v-t="'details'" />
                            <v-icon right>{{ icons.mdiChevronRight }}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { useState } from 'vuex-composition-helpers'
import { mdiOpenInNew, mdiChevronRight } from '@mdi/js'
import { useRouter } from '@core/utils'
import projectUtils from '@/utils/projectUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  setup() {
    const { statusText: projectStatusText, fetchStatusChoices: fetchProjectStatusChoices } = projectUtils()

    const { clientData } = useState('staff', ['clientData'])

    const { router } = useRouter()

    const goToProjectDetailsPage = (id) => {
      if (!id) return
      const route = router.resolve({ name: 'apps-project-view', params: { id } })
      window.open(route.href, '_blank')
    }

    onMounted(async () => {
      await fetchProjectStatusChoices()
    })

    return {
      projectStatusText,

      goToProjectDetailsPage,

      clientData,
      icons: {
        mdiOpenInNew,
        mdiChevronRight,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
