<template>
  <div id="user-view">
    <v-row>
      <v-col v-if="clientData.id" cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            clientName: `${clientData.name}`,
          }"
        />
        <client-bio-panel :loading="loading" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(`${tab.title}`) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" :key="clientData.id" v-model="userTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <client-tab-projects v-if="clientData.id" :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <client-tab-files :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <client-tab-reminders v-if="userTab === 2" @refetch-data="fetchClientData" />
          </v-tab-item>

          <v-tab-item>
            <client-tab-custom-fields :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <client-tab-internal-notes v-if="userTab === 4" :key="componentTabKey" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { mdiAccountOutline, mdiAccountCancelOutline, mdiLockOutline, mdiBookEditOutline, mdiBellOutline } from '@mdi/js'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import ClientBioPanel from './client-bio-panel/ClientBioPanel.vue'
import ClientTabInternalNotes from './client-tab-internal-notes/ClientTabInternalNotes.vue'
import ClientTabProjects from './client-tab-projects/ClientTabProjects.vue'
import ClientTabFiles from './client-tab-files/ClientTabFiles.vue'
import ClientTabCustomFields from './client-tab-custom-fields/ClientTabCustomFields.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'
import ClientTabReminders from '@/views/apps/staff/clients/client-view/client-tab-reminders/ClientTabReminders.vue'

export default {
  components: {
    BreadCrumbs,
    ClientBioPanel,
    ClientTabInternalNotes,
    ClientTabProjects,
    ClientTabFiles,
    ClientTabCustomFields,
    ClientTabReminders,
  },
  setup() {
    const tabs = [
      { icon: mdiAccountCancelOutline, title: 'projects' },
      { icon: mdiLockOutline, title: 'files' },
      { icon: mdiBellOutline, title: 'reminders' },
      { icon: mdiBookEditOutline, title: 'customFields' },
      { icon: mdiAccountOutline, title: 'internalNotes' },
    ]

    const userTab = ref(null)
    const loading = ref(false)

    const { getClientDetails } = useActions('staff', ['getClientDetails'])
    const { clientData } = useState('staff', ['clientData'])
    const { SET_CLIENT_DATA } = useMutations('staff', ['SET_CLIENT_DATA'])

    const componentTabKey = ref(0)
    const forceRerenderTab = () => {
      componentTabKey.value += 1
    }

    const fetchClientData = async () => {
      loading.value = true
      await getClientDetails(router.currentRoute.params.id)
      loading.value = false
      forceRerenderTab()
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchClientData()
      },
    )

    onMounted(async () => {
      await fetchClientData()
    })

    onUnmounted(() => {
      SET_CLIENT_DATA({
        projects: [],
      })
    })

    return {
      fetchClientData,

      componentTabKey,
      tabs,
      userTab,
      clientData,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
