<template>
  <div v-if="form" class="w-full">
    <v-row>
      <v-col v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="12">
        <v-divider />
      </v-col>
      <v-col v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="12">
        <upgrade-alert class="mb-0" />
      </v-col>
      <slot name="blocked-features"></slot>
      <v-col v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="12">
        <v-divider />
      </v-col>
    </v-row>
  </div>
  <div v-else class="w-full">
    <v-divider v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" class="mb-3" />
    <upgrade-alert />
    <slot name="blocked-features"></slot>
    <v-divider v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" class="mt-3" />
  </div>
</template>
<script>
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlert from '@/components/UpgradeAlert.vue'

export default {
  components: {
    UpgradeAlert,
  },
  props: {
    form: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
