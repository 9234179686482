export default function useEmployeeReminderTable(handleAsTask) {
  const tableColumns = () => {
    return [
      {
        text: 'title',
        value: 'title',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
        show: true,
        width: '200px',
      },
      {
        text: 'assignedTo',
        value: 'ownerName',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
        show: handleAsTask,
      },
      {
        text: 'file',
        value: 'isAssignedToFile',
        sortable: true,
        groupable: false,
        align: 'center',
        class: 'text-uppercase',
        show: true,
      },
      {
        text: 'remindDate',
        value: 'remindDate',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
        show: true,
      },
      {
        text: 'doneDate',
        value: 'doneDate',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
        show: handleAsTask,
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
        sortable: false,
        groupable: false,
        class: 'text-uppercase',
        show: true,
      },
    ]
  }

  const reminderChipColor = (item) => {
    if (item.isDone) {
      return 'secondary'
    }

    const remindDate = new Date(item.remindDate)
    const currentDate = new Date()
    const diffTime = remindDate.getTime() - currentDate.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays === 0) {
      return 'warning'
    }
    if (diffDays > 0 && diffDays <= 7) {
      return 'primary'
    }
    if (diffDays < 0) {
      return 'error'
    }

    return 'info'
  }

  return {
    tableColumns,
    reminderChipColor,
  }
}
