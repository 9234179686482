<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title> {{ $t('customFields') }} </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            v-for="(customField, index) in localCustomFields"
            :key="`CustomField-${customField.id}-${index}`"
            cols="12"
          >
            <v-text-field
              v-model="customField.field"
              outlined
              dense
              :label="customField.name"
              maxlength="100"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3"
              :loading="loading"
              :disabled="!areCustomFieldsChanged"
              @click="handleCustomFieldsSubmit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn outlined color="secondary" :disabled="!areCustomFieldsChanged" @click="restoreCustomFields">
              {{ $t('discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import _ from 'lodash'

export default {
  setup() {
    const { updateClientDetails } = useActions('staff', ['updateClientDetails'])
    const { clientData } = useState('staff', ['clientData'])

    const loading = ref(false)

    const localCustomFields = ref({})
    localCustomFields.value = _.cloneDeep(clientData.value.customFieldsValues)

    const handleCustomFieldsSubmit = async () => {
      loading.value = true
      await updateClientDetails({
        ...clientData.value,
        customFieldsValues: localCustomFields.value,
      })

      loading.value = false
    }

    const areCustomFieldsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(localCustomFields.value, clientData.value.customFieldsValues, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(clientData.value.customFieldsValues, localCustomFields.value, _.isEqual))
      )
    })

    const restoreCustomFields = () => {
      localCustomFields.value = _.cloneDeep(clientData.value.customFieldsValues)
    }

    return {
      handleCustomFieldsSubmit,
      restoreCustomFields,

      areCustomFieldsChanged,

      localCustomFields,
      loading,
    }
  },
}
</script>
