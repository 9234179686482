<template>
  <div>
    <v-breadcrumbs :items="breadCrumbs" class="pl-0">
      <template #item="{ item }">
        <v-breadcrumbs-item :to="item.to" exact>{{
          item.useI18n ? $t(item.text, { postfix: '' }) : item.text
        }}</v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { getCurrentInstance, computed } from '@vue/composition-api'

export default {
  props: {
    context: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(params) {
    const vm = getCurrentInstance().proxy

    const breadCrumbs = computed(() => {
      if (typeof vm.$route.meta.breadCrumb === 'function') return vm.$route.meta.breadCrumb.call(vm, params.context)

      return vm.$route.meta.breadCrumb
    })

    return {
      breadCrumbs,
    }
  },
}
</script>
