import _ from 'lodash'

export default function fileUploadUtils() {
  const setUploadedFileName = (file) => {
    // eslint-disable-next-line no-param-reassign
    file.name = file.file[0].filenameWithoutExtension
  }

  const addFileReminder = (file, extraData) => {
    let date = new Date()
    if (file.validUntil != null) date = new Date(file.validUntil)
    date.setDate(date.getDate() - 7)
    // eslint-disable-next-line no-param-reassign
    file.reminder = {
      title: `Renew ${file.name} before ${date.toISOString().substr(0, 10)}`,
      remindDate: date.toISOString().substr(0, 10),
      owner: null,
      isDone: false,
      ...extraData,
    }
  }

  const deleteFileReminder = (file) => {
    // eslint-disable-next-line no-param-reassign
    file.reminder = null
  }

  const parseFilesToCreate = (files) => {
    return files
      .filter((fileToUpload) => !_.isEmpty(fileToUpload.file[0]))
      .map((fileToUpload) => {
        return {
          name: fileToUpload.name,
          validUntil: fileToUpload.validUntil,
          types: fileToUpload.types.map((fileType) => (_.isPlainObject(fileType) ? fileType : { type: fileType })),
          file: fileToUpload.file[0].getFileEncodeBase64String(),
          ...(fileToUpload.reminder && { reminder: fileToUpload.reminder }),
        }
      })
  }

  const addFileToUpload = (files, { validUntil = false, types = false, reminder = false } = {}) => {
    const baseFileData = { file: null, name: '' }
    if (validUntil) baseFileData.validUntil = null
    if (types) baseFileData.types = []
    if (reminder) baseFileData.reminder = null

    files.push({ ...baseFileData })
  }

  const deleteFileToUpload = (files, index) => {
    files.splice(index, 1)
  }

  return {
    setUploadedFileName,
    addFileReminder,
    deleteFileReminder,
    parseFilesToCreate,
    addFileToUpload,
    deleteFileToUpload,
  }
}
