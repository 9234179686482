import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function projectUtils() {
  const statusChoices = ref([])
  const clientChoices = ref([])

  const {
    getProjectStatusChoices,
    getClientChoices,
    getAllEmployeesChoices,
    getProjectsFileTypes,
    getProjectTags,
  } = useActions('staff', [
    'getProjectStatusChoices',
    'getClientChoices',
    'getAllEmployeesChoices',
    'getProjectsFileTypes',
    'getProjectTags',
  ])

  const employeesChoices = ref([])
  const getEmployeesChoices = async () => {
    employeesChoices.value = await getAllEmployeesChoices()
  }

  const addPosition = (positions) => {
    positions.push({
      name: '',
      demand: 0,
      assignees: [],
      date_start: null,
      date_end: null,
    })
  }

  const deletePosition = (positionIndex, position, positions) => {
    positions.splice(positionIndex, 1)
  }

  const fetchStatusChoices = async () => {
    statusChoices.value = await getProjectStatusChoices()
  }

  const statusText = (projectData) => {
    return statusChoices.value.find((status) => status.value === projectData.status)?.text
  }

  const fetchClientChoices = async () => {
    clientChoices.value = await getClientChoices()
  }

  const fileTypes = ref([])
  const fetchFileTypes = async () => {
    fileTypes.value = await getProjectsFileTypes()
  }

  const tagsChoices = ref([])
  const fetchTagChoices = async () => {
    tagsChoices.value = await getProjectTags()
  }

  return {
    addPosition,
    deletePosition,
    fetchStatusChoices,
    fetchClientChoices,

    statusText,

    statusChoices,
    clientChoices,

    fetchFileTypes,
    fileTypes,

    getEmployeesChoices,
    employeesChoices,

    tagsChoices,
    fetchTagChoices,
  }
}
