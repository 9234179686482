<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'editClientInformation'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="clientDataLocal.name"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="clientDataLocal.registrationName"
                outlined
                dense
                maxlength="150"
                hide-details
                :label="$t('registrationName')"
                :placeholder="$t('registrationName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="clientDataLocal.description"
                outlined
                auto-grow
                :label="$t('description')"
                :placeholder="$t('description')"
                hide-details="auto"
                rows="4"
                maxlength="1000"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="clientDataLocal.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                outlined
                dense
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-combobox
                v-model="clientDataLocal.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox></v-col
            >

            <v-col cols="12">
              <v-text-field
                v-model="clientDataLocal.contactEmail"
                outlined
                dense
                maxlength="150"
                hide-details
                :label="$t('contactEmail')"
                :placeholder="$t('contactEmail')"
                :rules="[validators.emailValidator]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="clientDataLocal.taxId"
                outlined
                dense
                maxlength="150"
                hide-details
                :label="$t('taxId')"
                :placeholder="$t('taxId')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="clientDataLocal.street"
                outlined
                dense
                :label="$t('street')"
                :placeholder="$t('street')"
                maxlength="100"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="clientDataLocal.houseNumber"
                outlined
                dense
                :label="$t('houseNumber')"
                :placeholder="$t('houseNumber')"
                maxlength="20"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="clientDataLocal.flatNumber"
                outlined
                dense
                :label="$t('flatNumber')"
                :placeholder="$t('flatNumber')"
                maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="8"
              ><v-text-field
                v-model="clientDataLocal.city"
                outlined
                dense
                :label="$t('city')"
                :placeholder="$t('city')"
                maxlength="50"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="4"
              ><v-text-field
                v-model="clientDataLocal.zipCode"
                outlined
                dense
                :label="$t('zipCode')"
                :placeholder="$t('zipCode')"
                maxlength="10"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6"
              ><v-text-field
                v-model="clientDataLocal.state"
                outlined
                dense
                :label="$t('state')"
                :placeholder="$t('state')"
                maxlength="50"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6"
              ><v-text-field
                v-model="clientDataLocal.country"
                outlined
                dense
                :label="$t('country')"
                :placeholder="$t('country')"
                maxlength="50"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">{{
                $t('save')
              }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required, emailValidator } from '@core/utils/validation'
import _ from 'lodash'
import clientUtils from '@/utils/clientUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    clientData: {
      type: Object,
      required: true,
    },
    statusChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const clientDataLocal = ref({})
    clientDataLocal.value = _.cloneDeep(props.clientData)

    const { updateClientDetails } = useActions('staff', ['updateClientDetails'])

    const { fetchTagChoices, tagsChoices } = clientUtils()

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateClientDetails({
        ...clientDataLocal.value,
        tags: clientDataLocal.value.tags.map((tag) => {
          return _.isObject(tag) ? tag : { tag }
        }),
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      async (newVal) => {
        if (newVal) {
          clientDataLocal.value = _.cloneDeep(props.clientData)
          await fetchTagChoices()
        }
      },
    )

    const areDataChanged = computed(() => {
      return (
        clientDataLocal.value.name !== props.clientData.name ||
        clientDataLocal.value.description !== props.clientData.description ||
        clientDataLocal.value.status !== props.clientData.status ||
        clientDataLocal.value.registrationName !== props.clientData.registrationName ||
        clientDataLocal.value.contactEmail !== props.clientData.contactEmail ||
        clientDataLocal.value.taxId !== props.clientData.taxId ||
        clientDataLocal.value.street !== props.clientData.street ||
        clientDataLocal.value.houseNumber !== props.clientData.houseNumber ||
        clientDataLocal.value.flatNumber !== props.clientData.flatNumber ||
        clientDataLocal.value.city !== props.clientData.city ||
        clientDataLocal.value.zipCode !== props.clientData.zipCode ||
        clientDataLocal.value.state !== props.clientData.state ||
        clientDataLocal.value.country !== props.clientData.country ||
        !_.isEmpty(_.differenceWith(clientDataLocal.value.tags, props.clientData.tags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.clientData.tags, clientDataLocal.value.tags, _.isEqual))
      )
    })

    return {
      handleFormSubmit,

      valid,
      loading,
      bioEditForm,
      clientDataLocal,
      areDataChanged,

      tagsChoices,

      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>
