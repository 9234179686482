<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(clientData.clientIds, clientData.id, loading, {
                name: 'apps-client-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(clientData.clientIds, clientData.id, loading, {
                name: 'apps-client-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-between align-center w-full mb-2">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(clientData.clientIds, clientData.id) || loading"
              @click="
                goToPrevious(clientData.clientIds, clientData.id, loading, {
                  name: 'apps-client-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <span class="text-center">{{ clientData.name }}</span>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(clientData.clientIds, clientData.id) || loading"
              @click="
                goToNext(clientData.clientIds, clientData.id, loading, {
                  name: 'apps-client-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
          <v-chip
            label
            small
            color="primary"
            class="v-chip-light-bg primary--text font-weight-semibold text-capitalize ma-1 mb-2"
            >{{ statusText }}</v-chip
          >
          <span class="d-flex justify-center align-center flex-wrap">
            <v-chip
              v-for="(tag, index) in clientData.tags"
              :key="index"
              label
              small
              color="info"
              outlined
              class="font-weight-semibold ma-1"
              v-text="tag.tag"
            />
          </span>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('name') }}:</span>
              <span class="text--secondary">{{ clientData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('registrationName') }}:</span>
              <span class="text--secondary">{{ clientData.registrationName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-1">
              <span>
                <span class="font-weight-medium me-2">{{ $t('description') }}:</span>
                <span class="text--secondary">{{ clientData.description }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('projectsWorkload') }}:</span>
              <span class="text--secondary">{{ clientData.projectsWorkload }}%</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('contactEmail') }}:</span>
              <span class="text--secondary">{{ clientData.contactEmail }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('taxId') }}:</span>
              <span class="text--secondary">{{ clientData.taxId }}</span>
            </v-list-item>
          </v-list>

          <h2 v-t="'address'" class="text-xl font-weight-semibold mb-2 mt-4" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('street') }}:</span>
              <span class="text--secondary">{{ clientData.street }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('houseNumber') }}:</span>
              <span class="text--secondary">{{ clientData.houseNumber }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('flatNumber') }}:</span>
              <span class="text--secondary">{{ clientData.flatNumber }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('city') }}:</span>
              <span class="text--secondary">{{ clientData.city }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('zipCode') }}:</span>
              <span class="text--secondary">{{ clientData.zipCode }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('state') }}:</span>
              <span class="text--secondary">{{ clientData.state }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('country') }}:</span>
              <span class="text--secondary">{{ clientData.country }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-row justify="center">
            <v-col cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title class="justify-center text-h5">{{ $t('delete') }}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center flex-column">
                        <div>{{ $t('confirmationModalUndoneQuestion') }}</div>
                        <div>{{ $t('deleteClientProjectInfo') }}</div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteClientHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn> </v-col
          ></v-row>
        </v-card-actions>
      </v-card>

      <client-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :client-data.sync="clientData"
        :status-choices="statusChoices"
      ></client-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiPencilOutline, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { ref, onMounted, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import ClientBioEdit from './ClientBioEdit.vue'
import clientUtils from '@/utils/clientUtils'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    ClientBioEdit,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { fetchStatusChoices, statusChoices } = clientUtils()
    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const { deleteClient } = useActions('staff', ['deleteClient'])
    const { clientData } = useState('staff', ['clientData'])

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    const deleteClientHandler = async () => {
      await deleteClient(clientData.value.id)
      router.push({ name: 'apps-client-list' })
    }

    const statusText = computed(() => {
      return statusChoices.value.find((status) => status.value === clientData.value.status)?.text
    })

    onMounted(async () => {
      await fetchStatusChoices()
    })

    return {
      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,
      deleteClientHandler,

      statusText,

      clientData,
      statusChoices,
      isBioDialogOpen,
      isDeleteDialogOpen,
      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>
