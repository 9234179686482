<template>
  <div class="user-tab-security">
    <file-table
      :loading="loading"
      :files="localFiles"
      :file-types="fileTypes"
      handle-reminders
      handle-document-generation
      handle-types-and-valid-until
      :document-generator-data="{
        module: MODULE_CLIENTS,
        obj: clientData,
        target: clientData.name,
      }"
      :upload-file-callback="uploadClientFiles"
      :disable-delete="!$can(ACTIONS.DELETE, PRIVATE.STAFF.CLIENTS_FILES)"
      :delete-file-callback="deleteClientFile"
      :update-file-callback="updateClientFile"
      :file-target-object="{ clientId: clientData.id }"
      :reminder-target-object="{ client: clientData.id }"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import clientUtils from '@/utils/clientUtils'
import router from '@/router'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: {
    FileTable,
  },
  setup() {
    const { deleteClientFile, updateClientFile, uploadClientFiles, getClientDetails } = useActions('staff', [
      'deleteClientFile',
      'updateClientFile',
      'uploadClientFiles',
      'getClientDetails',
    ])
    const { clientData } = useState('staff', ['clientData'])

    const { fetchFileTypes, fileTypes } = clientUtils()

    const { MODULE_CLIENTS } = documentTemplateUtils()

    const localFiles = ref([])
    const loading = ref(false)

    localFiles.value = _.cloneDeep(clientData.value.files)

    const refetchData = async () => {
      loading.value = true
      await getClientDetails(router.currentRoute.params.id)
      localFiles.value = _.cloneDeep(clientData.value.files)
      await fetchFileTypes()
      loading.value = false
    }

    onMounted(async () => {
      await fetchFileTypes()
    })

    return {
      refetchData,
      uploadClientFiles,
      deleteClientFile,
      updateClientFile,

      clientData,
      fileTypes,
      localFiles,
      loading,

      MODULE_CLIENTS,
      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style scoped lang="scss"></style>
